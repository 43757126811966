import React, {Component} from 'react'
import Navbar from '../Components/NavBar'
import DmHeader from "../Components/DmHeader"
import DmBenefits from "../Components/dm_Benefits"
import DmLearning from "../Components/dm_Learning"
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'
export class DigitalMarketingPage extends Component {
  render() {
    document.title = "MBA in Digital Marketing";
    return (
      <div>
        <Helmet>
          <meta name="keywords" content="mba in digital marketing, digital marketing diploma, master's degree in digital marketing, masters in digital marketing, postgraduate digital marketig, digital marketing mba in india" />
          <meta name="description" content="Get online MBA|MCA|BBA|BCA|PGDCA courses from the India's top universities offered for working professionals and freshers. Know more about Courses, Universities, Fees structure, Admission Process, Duration and more..." />

        </Helmet>
        <Navbar/>
        <DmHeader/>
        <DmBenefits/>
        <DmLearning/>
        <Footer/>
      </div>
    )
  }
}

export default DigitalMarketingPage