import React, { useState} from "react";
import "../Styles/UniversitiesLanding.css";
import amity from "../Images/Amity.png";
import lpu from "../Images/LPU.png";
import Manipal from "../Images/Manipal.png";
import Jain from "../Images/Jain.png";
import Himalayan from "../Images/HimalayanGarhwal.png";
import ManavRachana from "../Images/ManavRachana.png";
import Mangalayatan from "../Images/Mangalayatan.png";
import NIMMS from "../Images/NMIMS.png";
import RabindranathTagore from "../Images/RabindranathTagoreUniv.png";
import Subharti from "../Images/Subharti.png";
import SureshGyan from "../Images/SureshGyan.png";
import location from "../Images/location-1.png";
import unitype from "../Images/insurance.png";
import established from "../Images/company.png";
import checked from "../Images/checked.png";
import point from "../Images/pointing-right.png";
import PopUpForm from "./PopUpForm";

function UniversitiesLanding() {
  const [openPopUp, setOpenPopUp] = useState(false);
  return (
    <section class="outer-sec">
      <div class="main-heading">
        <h1>Universites Associated With</h1>
      </div>
      <div class="main-grid">
        <div class="container">
          <div class="box">
            <div class="imgbox">
              <img src={amity} alt="Amity" />
            </div>
            <h1>Amity University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Noida, UttarPradesh</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2005</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A+</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={lpu} alt="LPU" />
            </div>
            <h1>Lovely Professional University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Phagwara, Punjab</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2005</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A+</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>

            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={Manipal} alt="Manipal" />
            </div>
            <h1>Manipal University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Manipal, Karnataka</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 1953</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A+</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={Jain} alt="Jain" />
            </div>
            <h1>Jain Global University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Bengaluru, Karnataka</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 1990</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A++</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={Subharti} alt="Subharti" />
            </div>
            <h1>Subharti University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Meerut, Uttar Pradesh</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2008</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={ManavRachana} alit="ManavRachana" />
            </div>
            <h1>Manav Rachana University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Faridabad, Haryana</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2004</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={SureshGyan} alit="SureshGyan" />
            </div>
            <h1>Suresh Gyan University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Jaipur, Rajasthan</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2008</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={Mangalayatan} alit="Mangalayatan" />
            </div>
            <h1>Mangalayatan University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Aligarh, Uttar Pradeshh</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2006</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={NIMMS} alit="NIMMS" />
            </div>
            <h1>NIMMS University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Mumbai, Maharastra</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 1981</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A+</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          <div class="box">
            <div class="imgbox">
              <img src={RabindranathTagore} alit="RabindranathTagore" />
            </div>
            <h1>RavindraNath Tagore University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Bhopal, Madhya Pradesh</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2010</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : B</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div>

          {/* <div class="box">
            <div class="imgbox">
              <img src={Himalayan} alit="HimalayanGarhwal" />
            </div>
            <h1>Himalayan Garhwal University</h1>
            <h2></h2>
            <div class="university-info">
              <div class="university-information">
                <img src={location} class="university-icon" alt="" />
                <h4>Location : Pauri Garhwal, Uttarakhand</h4>
              </div>
              <div class="university-information">
                <img src={unitype} class="university-icon" alt="" />
                <h4>University Type : Private</h4>
              </div>
              <div class="university-information">
                <img src={established} class="university-icon" alt="" />
                <h4>Established In : 2016</h4>
              </div>
              <div class="university-information">
                <img src={checked} class="university-icon" alt="" />
                <h4>NAAC Accrediation : A</h4>
              </div>
              <div class="university-information">
                <img src={point} class="university-icon" alt="" />
                <h4>Courses Offered :</h4>
              </div>
            </div>
            <div class="courses">
              <h4>MCA</h4>
              <h4>BCA</h4>
              <h4>MBA</h4>
              <h4>BBA</h4>
              <h4>M.Com</h4>
              <h4>B.Com</h4>
              <h4>BA</h4>
              <h4>MA</h4>
              <h4>B.Lib</h4>
              <h4>M.Lib</h4>
              <h4>BSW</h4>
              <h4>MSW</h4>
              <h4>Others</h4>
            </div>
            <button
              class="know-more"
              onClick={() => { setOpenPopUp(true);}}>
              Know More
            </button>
          </div> */}
        </div>
      </div>
      {openPopUp && <PopUpForm closePopUp={setOpenPopUp} />}
    </section>
  );
}

export default UniversitiesLanding;
