// src/components/Learning.js
import React from 'react';
import "../Styles/dm_Learning.css";
const dm_Learning = () => {
    return (
      <div className="learning-container">
        {/* Content up to the first paragraph */}
        <h2>What Will You Learn from MBA in Digital Marketing?</h2>
        <p>
            Masters in digital marketing is a comprehensive program that covers a wide range of topics
            to equip students with the knowledge and skills needed to excel in the dynamic and
            ever-evolving field of digital marketing. Here are some key topics typically covered in an
            MBA in Digital Marketing:
        </p>

        {/* Curriculum Table */}
        <table className="curriculum-table">
          <tbody>
            <tr>
              <td className="curriculum-cell">
                <p>1. Digital Marketing Fundamentals:</p>
                <ul>
                  <li>Overview of digital marketing concepts and principles</li>
                  <li>Evolution of digital marketing in the business landscape</li>
                </ul>
              </td>
              <td className="curriculum-cell">
                <p>2. Marketing Strategy and Planning:</p>
                <ul>
                  <li>Integration of digital marketing into overall business strategy</li>
                  <li>Developing effective marketing plans and campaigns</li>
                </ul>
              </td>
            </tr>
  
            <tr>
              <td className="curriculum-cell">
                <p>3. Consumer Behavior Analysis:</p>
                <ul>
                  <li>Understanding consumer behavior in the digital age</li>
                  <li>Target audience profiling and segmentation</li>
                </ul>
              </td>
              <td className="curriculum-cell">
                <p>4. Search Engine Optimization (SEO):</p>
                <ul>
                  <li>Techniques for optimizing website content</li>
                  <li>Keyword research and analysis</li>
                </ul>
              </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>5. Social Media Marketing:</p>
                <ul>
                <li>Leveraging social media platforms for marketing</li>
                <li>Social media strategy development and execution</li>
                </ul>
            </td>
            <td className="curriculum-cell">
                <p>6. Content Marketing:</p>
                <ul>
                <li>Creating and distributing valuable content</li>
                <li>Content strategy and storytelling</li>
                </ul>
            </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>7. Email Marketing:</p>
                <ul>
                <li>Designing effective email campaigns</li>
                <li>Email automation and analytics</li>
                </ul>
            </td>
            <td className="curriculum-cell">
                <p>8. Data Analytics and Insights:</p>
                <ul>
                <li>Collecting and analyzing data for informed decision-making</li>
                <li>Use of analytics tools for measuring campaign performance</li>
                </ul>
            </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>9. Digital Advertising:</p>
                <ul>
                <li>Paid advertising strategies on platforms like Google Ads and social media</li>
                <li>Budgeting and optimizing digital ad campaigns</li>
                </ul>
            </td>
            <td className="curriculum-cell">
                <p>10. Mobile Marketing:</p>
                <ul>
                <li>Designing mobile-friendly campaigns</li>
                <li>Mobile app marketing and optimization</li>
                </ul>
            </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>11. E-commerce Marketing:</p>
                <ul>
                <li>Strategies for marketing products and services online</li>
                <li>E-commerce platforms and trends</li>
                </ul>
            </td>
            <td className="curriculum-cell">
                <p>12. Legal and Ethical Aspects:</p>
                <ul>
                <li>Understanding legal and ethical considerations in digital marketing</li>
                <li>Compliance with data protection and privacy laws</li>
                </ul>
            </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>13. Brand Management in the Digital Age:</p>
                <ul>
                <li>Building and managing brand identity online</li>
                <li>Reputation management strategies</li>
                </ul>
            </td>
            <td className="curriculum-cell">
                <p>14. Innovation and Emerging Technologies:</p>
                <ul>
                <li>Keeping abreast of the latest trends and technologies in digital marketing</li>
                <li>Application of emerging technologies in marketing</li>
                </ul>
            </td>
            </tr>

            <tr>
            <td className="curriculum-cell">
                <p>15. Capstone Project or Internship:</p>
                <ul>
                <li>Practical application of knowledge through a hands-on project or internship</li>
                <li>Real-world experience in implementing digital marketing strategies</li>
                </ul>
            </td>
            {/* Add more rows as needed */}
            </tr>

  
            {/* Add more rows as needed */}
          </tbody>
        </table>

        {/* Summary and conclusion */}
        <p>
            This comprehensive curriculum ensures that graduates of Masters in digital marketing are
            well-prepared to navigate the complexities of the digital landscape and contribute
            strategically to the success of businesses in today's digital age.
        </p>
        <p>
            In summary, masters in digital marketing is not just a degree—it's a strategic investment
            in your professional future. Elevate your career, expand your horizons, and become a leader
            at the intersection of business and digital innovation. Embrace the transformative journey
            that awaits you.
        </p>
      </div>
    );
  };

export default dm_Learning;
