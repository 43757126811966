import React, { Component } from 'react'
import Navbar from '../Components/NavBar'
import Blogs from '../Components/Blogs'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'

export class BlogsPage extends Component {
  render() {
    document.title = "TOP EDUCATIONAL BLOG FOR DISTANCE AND ONLINE ADMISSIONS SERVICES";
    return (
      <div>
        <Helmet>
          
          <meta name="keywords" content="mba distance education, mca distance education, bba online course, mca degree distance education" />
          <meta name="description" content="Get online MBA|MCA|BBA|BCA|PGDCA courses from the India's top universities offered for working professionals and freshers. Know more about Courses, Universities, Fees structure, Admission Process, Duration and more..." />

        </Helmet>
        <Navbar/>
        <Blogs/>
        <Footer/>
      </div>
    )
  }
}

export default BlogsPage
