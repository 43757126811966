import React from 'react'
import pagenotfound from "../Images/404.jpg";

export default function NotFound404() {
  return (
    <div class="Outer-container-404" style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
      <img src={pagenotfound} alt="" style={{height:"75%"}}/>
    </div>
  )
}
