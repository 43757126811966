import React from 'react'
import Navbar from '../Components/NavBar'
import Registration from '../Components/Registration'
import Footer from '../Components/Footer'

export default function RegistrationPage() {
  return (
    <div>
      <Navbar/>
      <Registration/>
      <Footer/>
    </div>
  )
}
