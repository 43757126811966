import React, { useState } from "react";
import "../Styles/SignUp.css";
import LoginSignUp from "../Images/LoginSignUp.jpg";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../firebase";
import Swal from "sweetalert2";
import { collection, doc, addDoc, setDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [course,setCourseInterested]=useState("");
  const [conformpassword,setConformpassword]=useState("");

  const createDocument = async (userEmail) => {
    try {
      const docRef = doc(db, "Users", userEmail);
      await setDoc(docRef, { Name: name, Phone: phone, Email: email, Course: course });
    } catch (error) {
      console.error("Error creating document: ", error);
    }
  };

  const signUp = (e) => {
    e.preventDefault();

    {validateFields(name,email,phone,password,conformpassword)?
    Swal.fire({
      title: "Loading...",
      icon: "https://www.boasnotas.com/img/loading2.gif",
      buttons: false,
      closeOnClickOutside: false,
      timer: 3000,
    }).then(
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        sendEmailVerification(userCredential.user).then(createDocument(userCredential.user.email)).then(() => {
          Swal.fire({
            title: "Registered Successfully",
            text:
              "Verification Email Sent to " +
              userCredential.user.email + " , Please Check Your Spam if You haven't recieved",
            icon: "success",
            confirmButtonText: "OK",
          }).then(function () {
            window.location.href = "/signin";
          });
        });
      })

      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: errorBeautify(error.toString()),
          icon: "error",
          confirmButtonText: "OK",
        });
      })):e.preventDefault()};
  };

  function validateFields(name,email,phone,password,conformpassword){
    var regName = /^(?! )[A-Za-z\s]+(?<! )$/;
    var regPhoneno = /^\d{10}$/;
    var emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    var passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;

    if(!regName.test(name)){
      toast.error('Name can contain only alphabets and spaces', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    else if(!emailRegex.test(email)){
       toast.error('Please Check Your Email', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    else if(!regPhoneno.test(phone)){
       toast.error('Phone number can contain only 10 digits', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    else if(!passwordRegex.test(password)){
       toast.error('Password must have atleast 6 characters and should contain both numbers and alphabets', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }else if(password!==conformpassword){
      toast.error('Password and Confirm Password doesnot match.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      return true;
    }

  }


  function errorBeautify(inp) {
    const output = inp
      .match(/\(auth\/(.*)\)/)[1]
      .split("-") // split the string at the hyphen
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
      .join(" "); // join the words with a space
    return output;
  }
  return (
    <div class="sign">
      <div class="SignUp-outer-container">
        <div class="SignUp-inner-left">
          <img src={LoginSignUp} alt="" />
        </div>
        <div class="SignUp-inner-right">
          <div class="signup-form">
            <h1>SignUp</h1>
            <h2>Create Your Accout Now</h2>
            <form onSubmit={signUp}>
              <input type={Text} placeholder="Name" required value={name}
                onChange={(e) => setName(e.target.value)}></input>
              <input
                type="email"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <input type={Number} placeholder="Phone Number" required value={phone}
                onChange={(e) => setPhone(e.target.value)}></input>
              <select placeholder="Course Interested" required onChange={e => setCourseInterested(e.target.value)}>
                <option value={""}>-- Select the Course Interested --</option>
                <option value={"MBA"}>MBA</option>
                <option value={"BBA"}>BBA</option>
                <option value={"M.Com"}>M.Com</option>
                <option value={"B.Com"}>B.Com</option>
                <option value={"BA"}>BA</option>
                <option value={"MA"}>MA</option>
                <option value={"B.Lib"}>B.Lib</option>
                <option value={"M.Lib"}>M.Lib</option>
                <option value={"BSW"}>BSW</option>
                <option value={"MSW"}>MSW</option>
              </select>
              <input
                type="password"
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <input
                type="password"
                placeholder="Confirm Password"
                value={conformpassword}
                onChange={(e) => setConformpassword(e.target.value)}
                required
              ></input>
              <button type="submit">Sign Up</button>
              <h4>
                Already have an account ? <a href="/signin">Login</a>
              </h4>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUp;
