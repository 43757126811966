import React, { useState } from 'react';
import "../Styles/dm_Header.css";
import PopUpForm from './PopUpForm'; // Adjust the path based on your project structure

const DmHeader = () => {
  const [openPopUp, setOpenPopUp] = useState(false);

  return (
    <header className="header">
      <div className="header-content">
        <h1 className="header-title">Elevate Your Career with an MBA in Digital Marketing</h1>
        <p className="header-text">
          In today's dynamic business landscape, staying ahead requires a unique skill set that blends traditional
          business acumen with cutting-edge digital marketing strategies. Embrace the future of business education with
          our MBA in Digital Marketing program, designed to equip you with the knowledge and skills needed to thrive
          in the digital age. Whether you are a graduate, fresher, working professional, or entrepreneur, Digital
          Marketing in MBA will give you benefits in many ways because of its wide-range topics.
        </p>
        <p className="header-duration">DURATION: 2 YEARS</p>
        <button className="enrollBtn"
          onClick={() => {
            setOpenPopUp(true);
          }}
        >
          Enroll Now
        </button>
        {openPopUp && <PopUpForm closePopUp={() => setOpenPopUp(false)} />}
      </div>
    </header>
  );
};

export default DmHeader;
