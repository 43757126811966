import React, { useState, useEffect } from "react";
import "../Styles/Registration.css";
import { auth, db, storage } from "../firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

function Registration() {

    const [emailFolder, setEmailFolder] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                setEmailFolder(auth.currentUser.email);
            } else {
                setEmail(null);
            }
        });
    }, [emailFolder]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState(0);
    const [courseInterested, setCourseInterested] = useState('');
    const [address, setAddress] = useState('');

    const [nameSchool, setNameSchool] = useState('');
    const [gpaSchool, setGpaSchool] = useState(0);
    const [startDateSchool, setStartDateSchool] = useState('');
    const [endDateSchool, setEndDateSchool] = useState('');
    const [sscFile, setSscFile] = useState(null);

    const [nameIntermediate, setNameIntermediate] = useState('');
    const [gpaIntermediate, setGpaIntermediate] = useState(0);
    const [startDateIntermediate, setStartDateIntermediate] = useState('');
    const [endDateIntermediate, setEndDateIntermediate] = useState('');
    const [intermediateFile, setIntermediateFile] = useState(null);

    const [isUg, setIsUg] = useState('');

    const [nameUG, setNameUG] = useState('');
    const [gpaUG, setGpaUG] = useState(0);
    const [startDateUG, setStartDateUG] = useState('');
    const [endDateUG, setEndDateUG] = useState('');
    const [uGFile, setUGFile] = useState(null);

    const files = () => {
        if(isUg==="Yes"){
            return [sscFile, intermediateFile, uGFile];
        }else{
            return [sscFile,intermediateFile];
        }
    }

    const personalInformation = {
        FirstName: firstName,
        LastName: lastName,
        Gender: gender,
        DOB: dob,
        Email: email,
        Phone: phone,
        Course: courseInterested,
        Address: address
    }

    const sSCInformation = {
        SSC_SchoolName: nameSchool,
        SSC_GPA: gpaSchool,
        SSC_StartDate: startDateSchool,
        SSC_EndDate: endDateSchool
    }

    const intermediateInformation = {
        Intermediate_SchoolName: nameIntermediate,
        Intermediate_GPA: gpaIntermediate,
        Intermediate_StartDate: startDateIntermediate,
        Intermediate_EndDate: endDateIntermediate
    }

    const uGInformation = {
        UG_CollegeName: nameUG,
        UG_GPA: gpaUG,
        UG_StartDate: startDateUG,
        UG_EndDate: endDateUG
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        var regName = /^(?! )[A-Za-z\s]+(?<! )$/;
        var phoneno = /^\d{10}$/;
        var emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    
        if(!regName.test(firstName)){
          event.preventDefault();
          toast.error('First Name can contain only alphabets and spaces', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(!regName.test(lastName)){
            event.preventDefault();
             toast.error('Last Name can contain only alphabets and spaces', {
              position: toast.POSITION.TOP_RIGHT
            });
        }
        else if(!regName.test(nameSchool)){
            event.preventDefault();
             toast.error('School Name can contain only alphabets and spacesPlease Check Your Email', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
          else if(!regName.test(nameIntermediate)){
            event.preventDefault();
             toast.error('Intermediate College Name can contain only alphabets and spaces', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        else if(!emailRegex.test(email)){
          event.preventDefault();
           toast.error('Please Check Your Email', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(!phoneno.test(phone)){
          event.preventDefault();
           toast.error('Phone number must contain 10 digits', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(gpaSchool>10){
            event.preventDefault();
           toast.error('School GPA Should be less than or equals to 10', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(gpaIntermediate>10){
            event.preventDefault();
           toast.error('12th GPA Should be less than or equals to 10', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(gpaUG>10){
            event.preventDefault();
           toast.error('Under Graduate GPA Should be less than or equals to 10', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(new Date(startDateSchool)>=new Date(endDateSchool)){
            event.preventDefault();
           toast.error('School Start Date Should be Less than End Date', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else if(new Date(startDateIntermediate)>=new Date(endDateIntermediate)){
            event.preventDefault();
           toast.error('12th Start Date Should be Less than End Date', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        else{
        Swal.fire({
            title: "Uploading Files...",
            text: "Please Wait...",
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
            timer: 30000,
        })
        updateDocument(emailFolder, { Bio: { Personal_Information: personalInformation, SSC_Information: sSCInformation, Intermediate_Information: intermediateInformation, UG_Information: isUg ==="Yes" ? uGInformation:"", Documents: "https://console.firebase.google.com/project/distancestudyhub-bd4ed/storage/distancestudyhub-bd4ed.appspot.com/files/~2F" + emailFolder } }).then(
            uploadFiles(emailFolder.trim().toLowerCase(),files()));}
    };

    const updateDocument = async (docId, fieldValue) => { 
        try {
            const docRef = doc(db, "Users", docId);
            await updateDoc(docRef, fieldValue);
            console.log("Document updated successfully");
        } catch (error) {
            console.error("Error updating document: ", error);
            Swal.fire({
                title: "Oops...",
                text: error.toString(),
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    }

    const uploadFiles = async (folderName,files) => {

        const fileNames = ["SSC.pdf", "Intermediate.pdf", "UnderGraduate.pdf", "Graduate.pdf"]

        try {

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileRef = ref(storage, `${folderName}/${fileNames[i]}`);
                await uploadBytes(fileRef, file).then((snapshot) => {
                    console.log("File-" + (i + 1) + " Uploaded Successfully");
                })
            }

            Swal.fire({
                title: "Details Recieved!",
                text: "All Your Details and Files are uploaded.",
                icon: "success",
                confirmButtonText: "OK",
            }).then(function () {
                window.location.href = "/";
            });

        } catch (error) {
            console.error("Error uploading files: ", error);
            Swal.fire({
                title: "Oops...",
                text: error.toString(),
                icon: "error",
                confirmButtonText: "OK",
            });
        }

    }



    return (
        <div>
            <form className="main-container" onSubmit={handleSubmit}>
                <div className="first-box">
                    <h1>Application for Enrollment</h1>
                    <h2>Please fill out the application carefully</h2>
                </div>
                <div className="second-box">
                    <h2>Personal Information</h2>
                    <h3>Full Name</h3>
                    <div className="name">
                        <input type="text" placeholder="First Name" className="name-box" required value={firstName} onChange={e => setFirstName(e.target.value)} />
                        <input type="text" placeholder="Last Name" className="name-box" required value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                    <h3>Gender</h3>
                    <div className="gender">
                        <input type="radio" value="Male" name="gender" required onChange={e => setGender(e.target.value)} /> Male
                        <input type="radio" value="Female" name="gender" required onChange={e => setGender(e.target.value)} /> Female
                        <input type="radio" value="NotPreferToSay" name="gender" required onChange={e => setGender(e.target.value)} /> Not Prefer To Say
                    </div>
                    <h3>Date of Birth</h3>
                    <div className="date-of-birth">
                        <input type="date" required value={dob} onChange={e => setDob(e.target.value)} />
                    </div>
                    <h3>E-mail</h3>
                    <div className="email">
                        <input type="email" placeholder="Enter your email" required value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <h3>Phone Number</h3>
                    <div className="phoneno">
                        <input type="number" placeholder="+91XXXXXXXXX" required value={phone} onChange={e => setPhone(e.target.value)} />
                    </div>
                    <h3>Course Interested</h3>
                    <div className="courseSelect">
                        <select placeholder="Course Interested" required onChange={e => setCourseInterested(e.target.value)}>
                            <option value={""}>-- Select a Course --</option>
                            <option value={"MBA"}>MBA</option>
                            <option value={"BBA"}>BBA</option>
                            <option value={"M.Com"}>M.Com</option>
                            <option value={"B.Com"}>B.Com</option>
                            <option value={"BA"}>BA</option>
                            <option value={"MA"}>MA</option>
                            <option value={"B.Lib"}>B.Lib</option>
                            <option value={"M.Lib"}>M.Lib</option>
                            <option value={"BSW"}>BSW</option>
                            <option value={"MSW"}>MSW</option>
                        </select>
                    </div>
                    <h3>Address</h3>
                    <div className="address">
                        <textarea rows="4" cols="50" placeholder="Enter your address" required value={address} onChange={e => setAddress(e.target.value)}></textarea>
                    </div>
                    <div className="education">
                        <h1>Educational Background</h1>
                        <h4 >List All Your Previous Education</h4>

                        <div className="ug">
                            <p>Have You Completed Or Currently Pursuing Your UG : </p>
                            <div class="ug-radio">
                                <input type="radio" value="Yes" name="ug" required onChange={e => setIsUg(e.target.value)} /> Yes
                                <input type="radio" value="No" name="ug" required onChange={e => setIsUg(e.target.value)} /> No
                            </div>
                        </div>

                        <div >
                            <h2>10th Information </h2>
                            <div>
                                <h3>School name</h3>
                                <input className="schoolname" type="text" value={nameSchool} onChange={e => setNameSchool(e.target.value)} required />
                                <h3>GPA</h3>
                                <input className="gpa" type="number" step="0.01" value={gpaSchool} onChange={e => setGpaSchool(e.target.value)} required />
                                <div className="dateinformation">
                                    <div>
                                        <h3>Start Date</h3>
                                        <input className="date" type="date" value={startDateSchool} onChange={e => setStartDateSchool(e.target.value)} required />
                                    </div>
                                    <div>
                                        <h3>End Date</h3>
                                        <input className="date" type="date" value={endDateSchool} onChange={e => setEndDateSchool(e.target.value)} required />
                                    </div>
                                </div>
                                <h3>Upload</h3>
                                <input className="upload" type="file" accept="application/pdf" onChange={e => setSscFile(e.target.files[0])} required />
                            </div>
                        </div>
                        <div>
                            <h2>12th Information </h2>

                            <div>
                                <h3>School / College name</h3>
                                <input className="schoolname" type="text" value={nameIntermediate} onChange={e => setNameIntermediate(e.target.value)} required />
                                <h3>GPA</h3>
                                <input className="gpa" type="number" step="0.01" value={gpaIntermediate} onChange={e => setGpaIntermediate(e.target.value)} required />
                                <div className="dateinformation">
                                    <div>
                                        <h3>Start Date</h3>
                                        <input className="date" type="date" value={startDateIntermediate} onChange={e => setStartDateIntermediate(e.target.value)} required />
                                    </div>
                                    <div>
                                        <h3>End Date</h3>
                                        <input className="date" type="date" value={endDateIntermediate} onChange={e => setEndDateIntermediate(e.target.value)} required />
                                    </div>
                                </div>
                                <h3>Upload</h3>
                                <input className="upload" type="file" accept="application/pdf" onChange={e => setIntermediateFile(e.target.files[0])} required />
                            </div>
                        </div>

                        {isUg==="Yes"?
                        <div>
                            <h2>Under-Graduate Information </h2>

                            <div>
                                <h3>College name</h3>
                                <input className="schoolname" type="text" value={nameUG} onChange={e => setNameUG(e.target.value)} required />
                                <h3>GPA</h3>
                                <input className="gpa" type="number" step="0.01" value={gpaUG} onChange={e => setGpaUG(e.target.value)} required />
                                <div className="dateinformation">
                                    <div>
                                        <h3>Start Date</h3>
                                        <input className="date" type="date" value={startDateUG} onChange={e => setStartDateUG(e.target.value)} required />
                                    </div>
                                    <div>
                                        <h3>End Date</h3>
                                        <input className="date" type="date" value={endDateUG} onChange={e => setEndDateUG(e.target.value)} required />
                                    </div>
                                </div>
                                <h3>Upload</h3>
                                <input className="upload" type="file" accept="application/pdf" onChange={e => setUGFile(e.target.files[0])} required />
                            </div>
                        </div>:<></>}
                    </div>

                    <hr />

                    <div className="bottom-part">
                        <input inputid="checkbox1" value="isChecked" type="checkbox" required />
                        <label htmlFor="checkbox1"> &nbsp; I confirm and declare that all the Information provided is mine and is true to my Knowledge</label>
                    </div>
                    <input type="submit" value="Submit" class="registration-btn" />
                </div>
            </form>
            <ToastContainer />
        </div>
    );
}

export default Registration;