import React, { useRef, useState,} from "react";
import { useNavigate } from "react-router";
import "../Styles/Home.css";
import Rounded from "../Images/Rounded rectangle.png";
// import JobPlacement from "../Images/job_placement.gif"
import JobPlacement from "../Images/100job.gif"
import Line from "../Images/Line Indicator.png";
import Circle from "../Images/Circle.png";
import vector from "../Images/FAQ Vector.png";
import arrow from "../Images/Arrow 1.png";

import Subharti from "../Images/Subharti.png";
import SureshGyan from "../Images/SureshGyan.png";
import Manglayatan from "../Images/Mangalayatan.png";
import RavindraNath from "../Images/RabindranathTagoreUniv.png";
import Manipal from "../Images/Manipal.png";
import LPU from "../Images/LPU.png";
import Amity from "../Images/Amity.png";
import NIMMS from "../Images/NMIMS.png";
import Himalayan from "../Images/HimalayanGarhwal.png";
import Manav from "../Images/ManavRachana.png";
import Jain from "../Images/Jain.png";

import BA from "../Images/BA.png";
import BBA from "../Images/BBA.png";
import BSW from "../Images/BSW.png";
import MCom from "../Images/M-Com.png";
import MLib from "../Images/M-Lib.png";
import MA from "../Images/MA.png";
import MBA from "../Images/MBA copy.png";
import MSW from "../Images/MSW.png";
import BCom from "../Images/BCom.png";
import BLib from "../Images/BLib.png";
import Blogs from "./Blogs";
import HomeForm from "./HomeForm";
import { Typewriter } from "react-simple-typewriter";
import Carousel from "react-elastic-carousel";

const images = [
  Subharti,
  SureshGyan,
  Manglayatan,
  RavindraNath,
  Manipal,
  LPU,
  Amity,
  NIMMS,
  Himalayan,
  Manav,
  Jain,
];

function Home() {
  const divRef=useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState([false, false, false, false, false]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const scrollRef = React.createRef();
  const navigate = useNavigate();

  const toggleAnswer = (index) => {
    const newAnswers = [...answers];
    newAnswers[index] = !newAnswers[index];
    setAnswers(newAnswers);
  };
  const handleImageChange = (nextIndex) => {
    if (nextIndex < 0) {
      nextIndex = images.length - 1;
    } else if (nextIndex >= images.length) {
      nextIndex = 0;
    }
    setCurrentImageIndex(nextIndex);
  };

  const renderVisibleImages = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 992) {
      return images.slice(currentImageIndex, currentImageIndex + 3);
    } else if (windowWidth >= 576) {
      return images.slice(currentImageIndex, currentImageIndex + 2);
    } else {
      return images.slice(currentImageIndex, currentImageIndex + 1);
    }
  };

  const getCircleClass = (index) => {
    const start = currentImageIndex;
    const end = currentImageIndex + renderVisibleImages().length;
    return `image-circle ${index >= start && index < end ? "visible" : ""} ${
      index === currentImageIndex ? "active" : ""
    }`;
  };

  const handleKnowMoreClick = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [viewmoreActive, setviewmoreActive] = useState(false);
  const [limit, setlimit] = useState(3);
  const imgs = [MBA, BBA, MCom, BCom, MA, BA, MSW, BSW, MLib, BLib];

  const ViewmoreButton = () => {
    setlimit(limit == imgs.length ? 3 : imgs.length);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 315, itemsToShow: 2 },
    { width: 615, itemsToShow: 3 },
    { width: 915, itemsToShow: 4 },
    { width: 1215, itemsToShow: 5 },
  ];

  const toUniversitiesPage = () => {
    window.location.href = "universities";
  };

  return (
    <div>
      <section class="home-header">
        <div class="home-header-container">
          <h1>Boost Your Career With Us</h1>
          <h2>
            <Typewriter
              words={[
                "Online / Distance Education Admissions Open 2023-2024",
                "Click here to know More...",
              ]}
              loop={false}
              cursor
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={2000}
            />
          </h2>
          <button onClick={() => window.location.replace("/#form")}>Know More</button>
          <p class="mba_dm" ><a href="/mba-in-digital-marketing">MBA in Digital Marketing</a></p>
          <p class="mba_dm_placement" >
          <a href="/mba-in-digital-marketing">
          <img className="placementImage" src={JobPlacement} />
          </a>
          </p>
          
        </div>
      </section>

      <section>
        <div class="uni-heading">
          <h1>Associated Universities</h1>
          <h3>Here are some of our renowned associated top Universities that are
          highly popular in providing Online / Distance Education to
          their Students</h3>
        </div>
        <div class="carousel-images">
          <Carousel
            breakPoints={breakPoints}
            enableMouseSwipe={false}
            itemPadding={[10, 10]}
          >
            <img src={Manipal} onClick={toUniversitiesPage} />
            <img src={Amity} onClick={toUniversitiesPage} />
            <img src={LPU} onClick={toUniversitiesPage} />
            <img src={Jain} onClick={toUniversitiesPage} />
            <img src={Manav} onClick={toUniversitiesPage} />
            <img src={Manglayatan} onClick={toUniversitiesPage} />
            <img src={NIMMS} onClick={toUniversitiesPage} />
            <img src={Subharti} onClick={toUniversitiesPage} />
            <img src={SureshGyan} onClick={toUniversitiesPage} />
            <img src={RavindraNath} onClick={toUniversitiesPage} />
            <img src={Himalayan} onClick={toUniversitiesPage} />
          </Carousel>
        </div>
      </section>

      <div className="home6">
        <h1 className="home6-1"> The Process We Follow </h1>
        <h2 className="home6-2">
          We follow a very simple process for helping you get into the best
          institute that matches your profile
        </h2>
        <div className="home6-3">
          <div className="home6-4">
            <div className="home6-5">
              <img src={Line} alt="line" className="home6-line" />
              <img src={Circle} alt="circle" className="home6-circle" />
              <h2 className="home6-h2">Enquire</h2>
              <p className="home6-p">
                Fill the form with necessary details and requirements by
                clicking on Enquire Now
              </p>
            </div>
            <div className="home6-5">
              <img src={Line} alt="line" className="home6-line" />
              <img src={Circle} alt="circle" className="home6-circle" />
              <h2 className="home6-h2">Discuss</h2>
              <p className="home6-p">
                Discuss with your personal expert consultant and clear all your
                doubts and get all your available options
              </p>
            </div>
            <div className="home6-5">
              <img src={Line} alt="line" className="home6-line" />
              <img src={Circle} alt="circle" className="home6-circle" />
              <h2 className="home6-h2">Shortlist</h2>
              <p className="home6-p">
                Get your options shortlisted based on your profile and
                requirements under our guidance
              </p>
            </div>
            <div className="home6-5">
              <img src={Line} alt="line" className="home6-line" />
              <img src={Circle} alt="circle" className="home6-circle" />
              <h2 className="home6-h2">Enroll</h2>
              <p className="home6-p">
                Get enrolled into your desired course and best institute without
                any hassle along with our support at every step
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home7">
        <div className="home7-1">
          <h1>Click Here To Know More About Admission Procedure</h1>
          <a class="home7-2" onClick={() => window.location.replace("/#form")}>
            Enquire Now
          </a>
        </div>
      </div>

      <section class="courses-outer-sec">
        <div class="courses-main-heading-home">
          <h1>Courses We Offer</h1>
        </div>
        <div class="courses-main-grid">
          <div class="courses-container">
            {imgs.slice(0, limit).map((item) => (
              <div class="courses-box">
                <div class="courses-imgbox">
                  <img src={item} alt="Amity" onClick={() => window.location.replace("/courses")} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="courses-viewmore">
          <button onClick={ViewmoreButton}>
            {limit == imgs.length ? "View Less" : "View More"}
          </button>
        </div>
      </section>


      <div id="form">
        <HomeForm/>
      </div>

      <div className="home9">
        <div className="left-div">
          <div className="left-text">Frequently Asked Questions</div>
          <img src={vector} alt="Placeholder" />
        </div>
        <div className="right-div">
          <div className="question" onClick={() => toggleAnswer(0)}>
            <div className="question-text">
              1. Is distance education degree valid in India?
            </div>
            <div className="question-icon">{answers[0] ? "-" : "+"}</div>
          </div>
          {answers[0] && (
            <div className="question-answer">
              Yes, distance education degrees are valid in India. The University
              Grants Commission (UGC) is the regulatory body that recognizes and
              approves distance education programs offered by universities in
              India. The Distance Education Bureau (DEB) of the UGC is
              responsible for promoting, coordinating, and maintaining the
              standards of distance education in the country. Several
              universities in India, including some of the most reputed ones,
              offer distance education programs. These programs are designed to
              provide flexibility to students who are unable to attend regular
              on-campus classes. Distance education programs in India cover a
              wide range of subjects and fields, including management,
              engineering, law, education, and more. However, it is important to
              note that not all distance education programs offered in India are
              recognized by the UGC. Before enrolling in a distance education
              program, students should check the accreditation and recognition
              status of the university and the program they are interested in.
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(1)}>
            <div className="question-text">
              2. Since I cannot stop working, is it okay to do a MBA by distance
              learning or should I wait till I can afford to take time out for a
              regular MBA?
            </div>
            <div className="question-icon">{answers[1] ? "-" : "+"}</div>
          </div>
          {answers[1] && (
            <div className="question-answer">
              It ultimately depends on your personal situation and goals.
              Pursuing an MBA through distance learning can offer more
              flexibility and allow you to continue working while you study.
              However, it may also require a significant amount of
              self-discipline and time management skills to balance your work
              and study commitments. On the other hand, a regular full-time MBA
              program may offer a more immersive and structured learning
              experience, with opportunities to network with other students and
              professionals in the field. However, it may also require you to
              take time off from work, which can be challenging if you have
              financial responsibilities. Before making a decision, consider
              your personal and professional goals, your financial situation,
              and your ability to balance work and study commitments. You may
              also want to research the reputation and quality of MBA programs
              offered through both distance learning and regular full-time
              programs, and speak to current or former students to get a better
              understanding of their experiences.
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(2)}>
            <div className="question-text">
              3. What kind of courses can be pursued through distance learning?
            </div>
            <div className="question-icon">{answers[2] ? "-" : "+"}</div>
          </div>
          {answers[2] && (
            <div className="question-answer">
              There are a wide variety of courses that can be pursued through
              distance learning, including: <br />
              1. Certificate courses: These are short-term courses that
              typically provide training in a specific skill or area of
              expertise. <br />
              2. Diploma courses: These courses are more extensive than
              certificate courses and can take up to two years to complete. They
              typically provide a more comprehensive education in a particular
              field.
              <br />
              3. Undergraduate courses: Students can pursue a variety of
              undergraduate degree programs through distance learning, including
              Bachelor of Arts (BA), Bachelor of Science (BSc), Bachelor of
              Commerce (BCom), and more.
              <br />
              4. Postgraduate courses: Students can pursue postgraduate degrees,
              including Master of Arts (MA), Master of Science (MSc), Master of
              Business Administration (MBA), and more.
              <br />
              5. Professional courses: Distance learning programs are also
              available for professional courses such as Chartered Accountancy,
              Company Secretary, and other such courses.
              <br />
              6. Vocational courses: Students can also pursue vocational courses
              such as fashion designing, interior decoration, web designing, and
              more.
              <br />
              7. Language courses: Distance learning options are also available
              for language courses.
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(3)}>
            <div className="question-text">
              4. Is the cost differential proportionate to the cost-benefit
              equation between distance learning programme and a full-time
              programme?
            </div>
            <div className="question-icon">{answers[3] ? "-" : "+"}</div>
          </div>
          {answers[3] && (
            <div className="question-answer">
              The cost differential between a distance learning program and a
              full-time program can be a factor in the cost-benefit equation,
              but it is not the only factor. The cost-benefit equation involves
              a comparison of the costs of pursuing a particular education
              program with the benefits that the program can provide. The costs
              of a distance learning program may include tuition fees, course
              materials, and any technology or equipment required to participate
              in the program, such as a computer and internet connection. On the
              other hand, the costs of a full-time program may include tuition
              fees, room and board, transportation, and other expenses
              associated with attending classes on campus. The benefits of a
              distance learning program may include greater flexibility and
              convenience, the ability to study from anywhere, and the
              opportunity to balance work or family obligations with education.
              However, a full-time program may offer benefits such as more
              in-person interaction with faculty and fellow students, access to
              on-campus resources and facilities, and opportunities for
              networking and internships. Therefore, the cost differential alone
              may not be proportionate to the cost-benefit equation between
              distance learning and a full-time program. The cost-benefit
              equation depends on individual circumstances, preferences, and
              priorities, and it is important to consider all factors before
              making a decision.
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(4)}>
            <div className="question-text">
              5. Do I need to pay full course fees when I register?
            </div>
            <div className="question-icon">{answers[4] ? "-" : "+"}</div>
          </div>
          {answers[4] && (
            <div className="question-answer">
              No, at the time of registration, you only need to pay registration
              fees (once) and yearly course fees. After that, you need to pay
              exam fees at the time of the examination. The same procedure will
              apply in next year as well.{" "}
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(5)}>
            <div className="question-text">
              6. What will be the mode of examination in Distance/online
              courses?
            </div>
            <div className="question-icon">{answers[5] ? "-" : "+"}</div>
          </div>
          {answers[5] && (
            <div className="question-answer">Exam mode will be online. </div>
          )}
          <div className="question" onClick={() => toggleAnswer(6)}>
            <div className="question-text">
              7. Will study material be provided by the university?
            </div>
            <div className="question-icon">{answers[6] ? "-" : "+"}</div>
          </div>
          {answers[6] && (
            <div className="question-answer">
              Yes, Study material will be provided.{" "}
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(7)}>
            <div className="question-text">
              8. Does Distance Learning save money?
            </div>
            <div className="question-icon">{answers[7] ? "-" : "+"}</div>
          </div>
          {answers[7] && (
            <div className="question-answer">
              Not only does tuition tend to be lower, but many additional
              expenses, such as transportation costs and course materials, are
              eliminated in an online program. However, it's important to note
              that the average cost of online college is still a significant
              investment.{" "}
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(8)}>
            <div className="question-text">
              9. Who benefits from Distance Learning?
            </div>
            <div className="question-icon">{answers[8] ? "-" : "+"}</div>
          </div>
          {answers[8] && (
            <div className="question-answer">
              Distance learning is extremely important for those who cannot
              attend programs due to health complications, severe social
              anxiety, busy work schedules or parenting demands, or any other
              situations which make it necessary to be confined to the home.{" "}
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(9)}>
            <div className="question-text">
              10. Is  BBA  distance education valid in India or not?
            </div>
            <div className="question-icon">{answers[9] ? "-" : "+"}</div>
          </div>
          {answers[9] && (
            <div className="question-answer">
              Yes, BBA distance education valid in India and abroad also. However the University must be approved by UGC and DEB.{" "}
            </div>
          )}
          <div className="question" onClick={() => toggleAnswer(10)}>
            <div className="question-text">
              11. What are the courses approved for Subharti University distance learning?
            </div>
            <div className="question-icon">{answers[10] ? "-" : "+"}</div>
          </div>
          {answers[10] && (
            <div className="question-answer">
              In Subharti University distance learning 15 courses are approved in which MBA, BBA,  MA , MCOM, BCOM, BA, B.LIB etc. We are associated with <a href="/universities" > Swami Vivekanand Subharti University </a> distance education for many years.{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
