// src/components/Benefits.js
import React from 'react';
import "../Styles/dm_Benefits.css";
import posterImage from '../Images/dm_dsh-poster-1.jpeg';
const dm_Benefits = () => {
  return (
    <div className="benefits-container">
      <div className="benefit-box large-box">
        <h2>Why Choose an MBA in Digital Marketing?</h2>
        <p>
        In the fast-paced and ever-evolving landscape of business, pursuing Master's degree in Digital Marketing opens doors to a myriad of opportunities and advantages. Here's a closer look at the compelling <b> benefits </b> that come with this Master's degree in Digital Marketing:
        <ul>
          <li>Strategic Vision and Leadership Skills</li>
          <li>In-Depth Digital Expertise</li>
          <li>Data-Driven Decision-Making</li>
          <li>Global Market Readiness</li>
          <li>Adaptability and Innovation</li>
          <li>Networking Opportunities</li>
          <li>Entrepreneurial Edge</li>
          <li>Versatility Across Industries</li>
          <li>Continuous Learning and Adaptation</li>
          <li>Career Advancement</li>
        </ul>
        </p>
        {/* Add bullet points here */}
      </div>

      <div className="benefit-box small-box">
      <img src={posterImage} alt="DSH Poster 1" style={{ width: '100%', height: '100%' }} />
        {/* <h2>What will You learn from MBA in Digital Marketing?</h2>
        <p>
          Masters in digital marketing is a comprehensive program that covers a wide range of topics to equip students
          with the knowledge and skills needed to excel in the dynamic and ever-evolving field of digital marketing.
          Here are some key topics typically covered in an MBA in Digital Marketing:
          
        This digital marketing master's program covers essential topics, including strategy, consumer behavior, SEO, social media, and emerging technologies. Graduates gain practical experience, positioning them as strategic leaders in the ever-evolving digital landscape—a transformative investment in their professional future.
        </p> */}
        {/* Add bullet points here */}
      </div>
    </div>
  );
};

export default dm_Benefits;
