import './App.css';
import Home from './Pages/HomePage';
import AboutUsPage from './Pages/AboutUsPage';
import UniversitiesPage from './Pages/UniversitiesPage';
import CoursesPage from './Pages/CoursesPage';
import ContactUsPage from './Pages/ContactUsPage';
import BlogsPage from './Pages/BlogsPage';
import DigitalMarketingPage from './Pages/DigitalMarketingPage';
// import BlogContent from './Components/BlogContent';
import Firstblog from './Components/blog-1';
import Secondblog from './Components/blog-2';
import Thirdblog from './Components/blog-3';
import Fourthblog from './Components/blog-4';
import Fifthblog from './Components/blog-5';
import Sixthblog from './Components/blog-6';
import Seventhblog from './Components/blog-7';
import Eighthblog from './Components/blog-8';
import Ninthblog from './Components/blog-9';
import Tenthblog from './Components/blog-10';
import Eleventhblog from './Components/blog-11';
import {
  BrowserRouter,
  Routes,Route
} from "react-router-dom";
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import SignUpPage from './Pages/SignUpPage';
import SignInPage from './Pages/SignInPage';
import NotFound404 from './Components/NotFound404';
import RegistrationPage from './Pages/RegistrationPage';
import AdminPage from './Pages/AdminPage';
import { Helmet } from 'react-helmet';

// import ReactGA from "react-ga";

// const TRACKING_ID = "G-K4N61D6M9T";
// ReactGA.initialize(TRACKING_ID);

function App() {

  return (
    <div>
    <Helmet>
      <meta name="description" content="Get online MBA|MCA|BBA|BCA|PGDCA courses from the India's top universities offered for working professionals and freshers. Know more about Courses, Universities, Fees structure, Admission Process, Duration and more..." />
      
    </Helmet>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<AboutUsPage/>}/>
          <Route path="/universities" element={<UniversitiesPage/>}/>
          <Route path="/courses" element={<CoursesPage/>}/>
          <Route path="/contact" element={<ContactUsPage/>}/>
          <Route path="/blogs" element={<BlogsPage/>}/>
          <Route path="/mba-in-digital-marketing" element={<DigitalMarketingPage/>}/>
          <Route path="/blogs/content/online-mba-courses-in-india" element={<Firstblog/>}/>
          <Route path="/blogs/content/online-bca-distance-education" element={<Secondblog/>}/>
          <Route path="/blogs/content/online-mca-degree-distance-education" element={<Thirdblog/>}/>
          <Route path="/blogs/content/online-mba-distance-education" element={<Fourthblog/>}/>
          <Route path="/blogs/content/online-mba-in-digital-marketing" element={<Fifthblog/>}/>
          <Route path="/blogs/content/online-mba-in-finance" element={<Sixthblog/>}/>
          <Route path="/blogs/content/online-bba-course-in-india" element={<Seventhblog/>}/>
          <Route path="/blogs/content/online-mba-in-hr" element={<Eighthblog/>}/>
          <Route path="/blogs/content/online-mba-in-hospital-management" element={<Ninthblog/>}/>
          <Route path="/blogs/content/Distance_Study_Hub_Online_MBA_Courses_in_India" element={<Tenthblog/>}/>
          <Route path="/blogs/content/Distance_Study_Hub_online_MBA_in_Marketing_Management" element={<Eleventhblog/>}/>
          <Route path="/signin" element={<SignInPage/>}/>
          <Route path="/signup" element={<SignUpPage/>}/>
          <Route path="/forgotpassword" element={<ForgotPasswordPage/>}/>
          <Route path="/upload" element={<RegistrationPage/>}/>
          <Route path="/admin/dashboard/brijesh@1984_8799488096" element={<AdminPage/>}/>
          <Route path='*' element={<NotFound404/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
