import React, { Component, useEffect, useState } from "react";

import blog_img_1 from "../Images/blog-1-mba.jpg";
import blog_img_2 from "../Images/blog-2-mba.jpg";
import blog_img_3 from "../Images/blog-3-mca.png";
import blog_img_4 from "../Images/blog-4-bca.png";
import blog_img_5 from "../Images/blog-5-mba-dm.jpg";
import blog_img_6 from "../Images/mba_finance_bg.jpeg";
import blog_img_7 from "../Images/bba_blog_bg.jpeg";
import blog_img_8 from "../Images/mba_hr_bg.jpeg";
import blog_img_9 from "../Images/mba_hospital_management_bg.jpeg";
import blog_img_10 from "../Images/online_mba_blog_bg.jpeg";
import blog_img_11 from "../Images/online_mba_marketing_bg.jpeg";


function Blogs() {

  useEffect(() => {
    fetch('https://www.jsonblob.com/1105097882985578496')
  .then(response => console.log(response))

  // .then(response => response.json())
  // .then(data => console.log(data))
  // .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <div class="blogs-container">
        <h1>Blogs</h1>
        <main class="blogs-grid">
          {/* article-1 -- start */}
          <article>
            <img src={blog_img_1} alt="" />
            <div class="blogs-text">
            <h2>
                  Online MBA courses in India
            </h2>
              <h4>Pursuing Online MBA: The Changing Landscape of Education in India</h4>
              <p>
              In recent years, India has witnessed a significant shift in the way people pursue higher education, particularly in the field of business administration. Traditional on-campus MBA programs are no longer the sole option for those looking to advance their careers and acquire valuable business knowledge. The rise of online MBA courses in India has revolutionized the education sector, offering a more flexible and accessible path to achieving your academic and professional goals.
              </p>
              <a href="/blogs/content/online-mba-courses-in-india">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-1 -- end */}

        {/* article-4 -- start */}
        <article>
            <img src={blog_img_4} alt="" />
            <div class="blogs-text">
              <h2>
                Online BCA distance education
              </h2>
              <h4>Navigating the Digital Frontier: BCA Distance Education Unveiled</h4>
              <p>
              The Bachelor of Computer Applications (BCA) is an academic pathway that propels students into the digital age, preparing them for an array of IT careers. One remarkable facet of BCA education is the availability of distance learning programs. In this blog, we explore the world of BCA distance education, delving into its advantages, challenges, and how to make the most of this digital frontier.
              </p>
              <a href="/blogs/content/online-bca-distance-education">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-4 -- end */}

          {/* article-3 -- start */}
          <article>
            <img src={blog_img_3} alt="" />
            <div class="blogs-text">
              <h2>
                  Online MCA degree Distance Education
              </h2>
              <h4>Mastering the Digital Realm: The Journey of MCA Distance Education</h4>
              <p>
              In today's technology-driven world, the demand for professionals with expertise in computer applications is higher than ever. Pursuing a Master of Computer Applications (MCA) through distance education can be a smart choice, offering the flexibility to acquire advanced knowledge and skills in the field while managing other commitments. In this blog, we'll delve into the world of MCA distance education, discussing its benefits, challenges, and tips for success.
              </p>
              <a href="/blogs/content/online-mca-degree-distance-education">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-3 -- end */}


          {/* article-2 -- start */}
          <article>
            <img src={blog_img_2} alt="" />
            <div class="blogs-text">
            <h2>
                Online MBA Distance Education
            </h2>
              <h4>Unlocking Success: Navigating the MBA Distance Education Journey</h4>
              <p>
              In a rapidly evolving world, pursuing an MBA has become more accessible than ever, thanks to the advent of distance education programs. This transformative approach to learning enables individuals to acquire valuable business knowledge and leadership skills from the comfort of their homes or offices. In this blog, we'll explore the benefits, challenges, and tips for success in MBA distance education.
              </p>
              <a href="/blogs/content/online-mba-distance-education">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-2 -- end */}

          {/* article-5 -- start */}
          <article>
            <img src={blog_img_5} alt="" />
            <div class="blogs-text">
            <h2>
                Online MBA in Digital Marketing
            </h2>
              <h4>Navigating the Digital Horizon: Unleashing the Potential of an MBA in Digital Marketing.</h4>
              <p>
              In a world where businesses are increasingly reliant on digital strategies to stay competitive, the pursuit of knowledge in digital marketing has become more crucial than ever. Master's degree in Digital Marketing isn't just a degree; it's a transformative journey that empowers individuals with the skills and insights needed to thrive in the fast-paced and dynamic landscape of digital commerce. Let's explore the myriad ways an MBA in Digital Marketing can reshape your career trajectory and open doors to unprecedented opportunities.
              </p>
              <a href="/blogs/content/online-mba-in-digital-marketing">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-5 -- end */}
          
        {/* article-6 -- start */}
          <article>
            <img src={blog_img_6} alt="" />
            <div class="blogs-text">
            <h2>
               Online MBA in Finance
            </h2>
              <h4>
                Master Your Financial Future with an Online MBA in Finance Introduction:
              </h4>
              <p>
              In today's fast-paced and dynamic business environment, staying ahead of the curve requires specialized knowledge and skills, especially in finance. An Online MBA in Finance can be your ticket to unlocking new opportunities and accelerating your career. Let's explore why pursuing an Online MBA in Finance is a strategic move toward mastering the complexities of the financial world.
              </p>
              <a href="/blogs/content/online-mba-in-finance">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-6 -- end */}

        {/* article-7 - bba -- start */}
          <article>
            <img src={blog_img_7} alt="" />
            <div class="blogs-text">
            <h2>
                Online BBA Course in India
            </h2>
              <h4>
                Navigating Success: The Ultimate Guide to Pursuing an Online BBA in India
              </h4>
              <p>
              In the rapidly evolving landscape of higher education, the demand for flexible and accessible learning options has given rise to the popularity of online courses. Pursuing a Bachelor of Business Administration (BBA) online has gained significant traction in India. This blog explores the advantages and opportunities of opting for an online BBA degree in India.
              </p>
              <a href="/blogs/content/online-bba-course-in-india">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-7 -- end */}

        {/* article-8 - mba-hr -- start */}
          <article>
            <img src={blog_img_8} alt="" />
            <div class="blogs-text">
            <h2>
                Online MBA Course in India
            </h2>
              <h4>
              The Power of an Online MBA in Human Resources
              </h4>
              <p>
              Human Resources (HR) is becoming increasingly crucial in the ever-evolving business landscape. As organizations recognize the importance of effective HR management, professionals seeking to advance their careers are turning to online Master of Business Administration (MBA) programs specializing in HR. This blog post explores the benefits and unique aspects of pursuing an online MBA in HR.
              </p>
              <a href="/blogs/content/online-mba-in-hr">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-8 -- end */}

        {/* article-9 - mba-hospital -- start */}
          <article>
            <img src={blog_img_9} alt="" />
            <div class="blogs-text">
            <h2>
                Online MBA Course in Hospital Management
            </h2>
              <h4>
              Navigating the Future of Healthcare Leadership: Pursuing an Online MBA in Hospital Management
              </h4>
              <p>
              In the ever-evolving landscape of healthcare, effective leadership is crucial for the success and sustainability of healthcare institutions. As the industry faces unprecedented challenges and opportunities, professionals with a potent blend of managerial and healthcare expertise are in high demand. Aspiring healthcare leaders can acquire these essential skills by pursuing an online MBA in Hospital Management.
              </p>
              <a href="/blogs/content/online-mba-in-hospital-management">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-9 -- end */}

        {/* article-10 - online-mba-blog -- start */}
          <article>
            <img src={blog_img_10} alt="" />
            <div class="blogs-text">
            <h2>
              The Rise of Online MBA Courses in India
            </h2>
              
              <p>
              In the fast-paced and ever-evolving landscape of business, pursuing an MBA has become more accessible than ever, thanks to the advent of online education. With its burgeoning economy and tech-savvy workforce, India has witnessed a significant surge in the popularity of online MBA programs. This blog post delves into the transformative journey of pursuing online MBA courses in India, exploring the benefits, challenges, and changing face of business education...
              </p>
              <a href="/blogs/content/Distance_Study_Hub_Online_MBA_Courses_in_India">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-10 -- end */}

        {/* article-11 - online-mba-marketing -- start */}
          <article>
            <img src={blog_img_11} alt="" />
            <div class="blogs-text">
            <h2>
              Online MBA in Marketing Management
            </h2>
            <h4>
              Revolutionizing Your Career: Unleashing the Power of an Online MBA in Marketing Management
              </h4>
              <p>
              Discover the remarkable impact of an online MBA in marketing management on today's fiercely competitive marketing landscape, where digital prowess and adaptability are paramount. Beyond equipping you with cutting-edge marketing techniques, this dynamic program offers unparalleled flexibility and agility to navigate the ever-evolving digital terrain...
              </p>
              <a href="/blogs/content/Distance_Study_Hub_online_MBA_in_Marketing_Management">Read Full Article &rarr;</a>
            </div>
          </article>

        {/* article-11 -- end */}



        </main>
      </div>
    </div>
  );
}

export default Blogs;
