import { auth, db } from "../firebase";
import { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, setDoc } from "firebase/firestore";
import React from 'react';
import '../Styles/Dashboard.css';
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

function Dashboard() {

    useEffect(() => {

        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "Users"));
                const currdata = querySnapshot.docs.map(doc => doc.data());
                setData(currdata);
            } catch (error) {
                console.error('Error fetching collection:', error);
            }
        };

        fetchData();

    }, []);

    const [data, setData] = useState([]);

    const [editableRow, setEditableRow] = useState();
    const [name, setName] = useState();
    const [number, setNumber] = useState();

    const handleDelete = async (Email) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteDB(Email).then(
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    ));
            }
        })
    };

    const handleDeleteDB = async (Email) => {
        setData(data.filter(item => item.Email !== Email));
        await deleteDoc(doc(db, "Users", Email)).then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    const handleEdit = (Email) => {
        setEditableRow(Email);
        data.map(item => {
            if (item.Email === Email) {
                setName(item.Name);
                setNumber(item.Phone);
            }
        });
    };

    const handleSave = (Email) => {

        if(validateFields(name,number)){
            let tempFile = {};
        const updatedData = data.map(item => {
            if (item.Email === Email) {
                tempFile = item;
                tempFile['Name'] = name;
                tempFile['Phone'] = number;
                return {
                    ...item,
                    ['Name']: name,
                    ['Phone']: number
                };
            }
            return item;
        });
        setData(updatedData);
        setEditableRow(null);
        updateDocument(Email, tempFile);

        }
    };

    const updateDocument = async (userEmail, updatedFile) => {
        try {
            const docRef = doc(db, "Users", userEmail);
            await setDoc(docRef, updatedFile);
        } catch (error) {
            console.error("Error creating document: ", error);
        }
    };

    function validateFields(name,phone){
        var regName = /^(?! )[A-Za-z\s]+(?<! )$/;
        var regPhoneno = /^\d{10}$/;
    
        if(!regName.test(name)||name===""){
          toast.error('Name can contain only alphabets and spaces', {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }
        else if(!regPhoneno.test(phone)){
           toast.error('Phone number can contain only 10 digits', {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }else{
          return true;
        }
    
      }




    return (
        <div>
            <table className="DashBoardTable">
                <thead>
                    <tr className="DashBoardHeader">
                        <th>Email</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(row => (
                        <tr key={row.Email} className="DashBoardRow">
                            <td>
                                {row.Email}
                            </td>

                            <td>
                                {editableRow == row.Email ? (
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                ) : (
                                    row.Name
                                )}
                            </td>

                            <td>
                                {editableRow == row.Email ? (
                                    <input
                                        type="text"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                ) : (
                                    row.Phone
                                )}
                            </td>
                            <td>
                                {editableRow == row.Email ? (
                                    <button className="saveBtn" onClick={() => handleSave(row.Email)}>Save</button>
                                ) : (
                                    <>
                                        <button className="deleteBtn" onClick={() => handleDelete(row.Email)}>Delete</button>
                                        <button className="editBtn" onClick={() => handleEdit(row.Email)}>Edit</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer/>
        </div>

    )
}

export default Dashboard
