import { auth, db } from "../firebase";
import { useEffect, useState } from 'react';
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import React from 'react';
import '../Styles/EnquireDashboard.css';
import Swal from "sweetalert2";
function EnquireDashboard() {

    useEffect(() => {

        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "Enquire-Form"));
                const currdata = querySnapshot.docs.map(doc => doc.data());
                setData(currdata);
            } catch (error) {
                console.error('Error fetching collection:', error);
            }
        };

        fetchData();

    }, []);

    const [data, setData] = useState([]);

    const handleUpdateStatus = (Email) => {
        let tempFile = {};
        const updatedData = data.map(item => {
            if (item.Email === Email) {
                tempFile = item;
                return {
                    ...item,
                    Resolved: true
                };
            }
            return item;
        });

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setData(updatedData);
                tempFile['Resolved'] = true;
                updateDocument(Email, tempFile).then(
                    Swal.fire(
                        'Updated!',
                        'Status Upated to Resolved.',
                        'success'
                    ));
            }
        })
    };

    const updateDocument = async (userEmail, updatedFile) => {
        try {
            const docRef = doc(db, "Enquire-Form", userEmail);
            await setDoc(docRef, updatedFile);
        } catch (error) {
            console.error("Error creating document: ", error);
        }
    };


    return (
        <div>
            {/* <h1>Admin EnquireDashboard</h1> */}
            <table className="EnquireDashboardTable">
                <thead>
                    <tr className="EnquireDashboardHeader">
                        <th>Email</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Course</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(row => (
                        <tr key={row.Email} className="EnquireDashboardRow">
                            <td>
                                {row.Email}
                            </td>
                            <td>
                                {row.Name}
                            </td>
                            <td>
                                {row.Phone}
                            </td>
                            <td>
                                {row.Course}
                            </td>
                            <td>
                                {row.Date}
                            </td>
                            <td>
                                {String(row.Resolved)}
                            </td>
                            <td>
                                {!row.Resolved?<button onClick={() => { handleUpdateStatus(row.Email) }}>Update Status</button>:<></>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    )
}

export default EnquireDashboard
