// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDb-za5brVVjAyIST8dCxoeeO-c1zmTM2M",
  authDomain: "distancestudyhub-bd4ed.firebaseapp.com",
  projectId: "distancestudyhub-bd4ed",
  storageBucket: "distancestudyhub-bd4ed.appspot.com",
  messagingSenderId: "300169110228",
  appId: "1:300169110228:web:bf413cfcd374ee4b525141"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const db = getFirestore(app)

export const storage=getStorage(app);