import React, { Component } from 'react'
import SignIn from '../Components/SignIn'

export class SignInPage extends Component {
    render() {
      return (
        <div>
          <SignIn/>
        </div>
      )
    }
  }

export default SignInPage
