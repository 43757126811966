import React, { Component, useEffect, useState } from "react";
import "../Styles/AboutUs.css";
import aboutuscontent from "../Images/aboutus.jpg";
import experience from "../Images/rating.png";
import studgrad from "../Images/education.png";
import studplac from "../Images/businessman.png";

function AboutUs() {
  const [startValues, setStartValues] = useState([]);

  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount1(count1 => count1<15?count1 + 1:count1);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount2(count2 => count2<458?count2 + 1:count2);
    }, 10);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        setCount3(count3 => count3<580?count3 + 1:count3);
    }, 8);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const valueDisplays = document.querySelectorAll(".counter-num");
  //   const interval = 1000;
  //   const newStartValues = [];

  //   valueDisplays.forEach((valueDisplay) => {
  //     const endValue = parseInt(valueDisplay.getAttribute("data-val"));
  //     const duration = Math.floor(interval / endValue);

  //     const counter = setInterval(function () {
  //       const newStartValue = newStartValues.pop() || 0;
  //       const updatedStartValue = newStartValue + 1;
  //       valueDisplay.textContent = updatedStartValue;

  //       if (updatedStartValue === endValue) {
  //         clearInterval(counter);
  //       } else {
  //         newStartValues.push(updatedStartValue);
  //       }
  //     }, duration);

  //     newStartValues.push(0);
  //   });

  //   setStartValues(newStartValues);
  // }, []);

  const [readmoreActive,setreadmoreActive]=useState(false);

  const ReadmoreButton = () => {
    setreadmoreActive(prevState => !prevState);
  }

  const para1="We have helped many students in completing their graduation and Post-Graduation in the distance, Private and online modes who are working on lower salaries due to their financial issues.We have also supported working professionals to achieve their academic goals without leaving their Jobs.";
  const para2="We have a team of Experts who can guide students and working professionals for the right course with maximum scope in the job market. We follow a very simple process for helping you get into the best institute that matches your profile."
  return (
    <div>
      <div class="main-heading">
        <h1>About Us</h1>
      </div>
      <section class="aboutus-desc">
        <div class="aboutus-row">
          <div class="aboutus-row-left">
            <div class="aboutus-row-content">
              <h1>We Believe...</h1>
              <h2>Explore | Upgrade | Grow</h2>
              <p>
                Distance Study Hub is one of the leading educational Consultant
                in India for Online and distance learning courses. Established
                in 2020, our core focus is to provide education to all working
                enthusiasts who are not getting the growth due to less
                educational qualifications.
              </p>

              <p>{readmoreActive?para1:""}</p>
              <p>{readmoreActive?para2:""}</p>

              <button onClick={ReadmoreButton}>{readmoreActive ? "Read Less" : "Read More"} &rarr;</button>
            </div>
          </div>
          <div class="aboutus-row-right">
            <img src={aboutuscontent} alt=""></img>
          </div>
        </div>
      </section>

      <div class="services">
        <div class="max-width">
          <h1>Process We Follow</h1>
          <div class="content">
            <div class="card">
              <div class="box">
                <i class="fa fa-question-circle"></i>
                <h3>Enquire</h3>
                <p>
                  Fill the form with necessary details and requirements by
                  clicking on Enquire Now.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="box">
                <i class="fa fa-group"></i>
                <h3>Discuss</h3>
                <p>
                  Discuss with your personal expert consultant and clear all
                  your doubts and get all your available options.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="box">
                <i class="fa fa-pencil-square-o"></i>
                <h3>Shortlist</h3>
                <p>
                  Get your options Shortlisted based on your profile and
                  requirements under our guidance.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="box">
                <i class="fa fa-handshake-o"></i>
                <h3>Enroll</h3>
                <p>
                  Get enrolled into your desired course and best Institute with
                  out any hassle along with our support at every step.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w3-quotes">
        <div class="m-auto">
          <blockquote class="blockquote quote">
            <q>
              The most profound words will remain unread unless you can keep the
              learner engaged. You can't see their eyes to know if they got it
              so … say it, show it, write it, demo it and link it to an
              activity.
            </q>
            <footer class="blockquote-footer mt-3"> - Henry Ford</footer>
          </blockquote>
        </div>
      </div>

      <section class="achievements">
        <h1>Our Achievements</h1>
        <div class="counter">
          <div class="counter-wrapper">
            <div class="counter-box-area">
              <div class="counter-icon-area">
                <img src={experience} alt="" />
              </div>
              <h6 className="counter-num" data-val="17">
                {count1}
              </h6>
              <p>Experience</p>
            </div>
            <div class="counter-box-area">
              <div class="counter-icon-area">
                <img src={studplac} alt="" />
              </div>
              <h6 className="counter-num" data-val="438">
                {count2}
              </h6>
              <p>Students Placed</p>
            </div>
            <div class="counter-box-area">
              <div class="counter-icon-area">
                <img src={studgrad} alt="" />
              </div>
              <h6 className="counter-num" data-val="569">
                {count3}
              </h6>
              <p>Students Graduated</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
