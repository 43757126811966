import React from "react";
import "../Styles/HomeForm.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, db } from "../firebase";
import Swal from "sweetalert2";
import { collection, doc, addDoc, setDoc } from "firebase/firestore";

function HomeForm() {

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp);
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };

    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(dateObj);
}

const createDocument = async (formData) => {
    try {
      const docRef = doc(db, "Enquire-Form", formData.email);
      await setDoc(docRef, { Name: formData.name, Phone: formData.phone, Email: formData.email, Course: formData.course, Resolved: false, Date: formatTimestamp(Date.now())}).then(
      toast.success('Form Submitted', {
        position: toast.POSITION.TOP_RIGHT
    }));
    window.location.replace("/");
    } catch (error) {
      console.error("Error creating document: ", error);
      toast.error("Error creating document: " + error, {
        position: toast.POSITION.TOP_RIGHT
    });
    }
};

  const onSubmits = (event) => {

    let formData = {
      name: event.target[0].value,
      email: event.target[1].value,
      phone: event.target[2].value,
      course: event.target[3].value,
    };

    console.log(formData);

    var regName = /^(?! )[A-Za-z\s]+(?<! )$/;
    var phoneno = /^\d{10}$/;
    var emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

    if(!regName.test(formData.name)){
      event.preventDefault();
      toast.error('Name can contain only alphabets and spaces', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else if(!emailRegex.test(formData.email)){
      event.preventDefault();
       toast.error('Please Check Your Email', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else if(!phoneno.test(formData.phone)){
      event.preventDefault();
       toast.error('Phone number can contain only 10 digits', {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      event.preventDefault();
      createDocument(formData);
    }
    
  };

  return (
    <section>
      <div class="form-container" id="homeform">
        <div class="form-contact-box">
          <div class="form-left"></div>
          <div class="form-right">
            <h2>Fill Out Your Details Below And Help Us Reach Out To You</h2>
            <form onSubmit={onSubmits}>
              <input
                type="text"
                class="form-field"
                placeholder="Your Name"
                name="name"
                required
              />
              <input
                type="email"
                class="form-field"
                placeholder="Your Email"
                name="email"
                required
              />
              <input
                type="number"
                class="form-field"
                placeholder="Phone"
                name="phone"
                required
              />
              {/* {Perror ? <label>Please Check Your Phone</label> : ""} */}
              {/* <select
                placeholder="University Interested"
                class="form-field"
                name="university"
                required
              >
                <option value={""}>-- Select a University --</option>
                <option value={"Amity"}>Amity University</option>
                <option value={"LPU"}>Lovely Professional University</option>
                <option value={"Manipal"}>Manipal University</option>
                <option value={"Jain"}>Jain Global University</option>
                <option value={"Subharti"}>Subharti University</option>
                <option value={"ManavRachana"}>Manav Rachana University</option>
                <option value={"SureshGyan"}>Suresh Gyan University</option>
                <option value={"Mangalayatan"}>Mangalayatan University</option>
                <option value={"NIMMS"}>NIMMS University</option>
                <option value={"RavindraNathTagore"}>
                  RavindraNath Tagore University
                </option>
                <option value={"HimalayanGarhwal"}>
                  Himalayan Garhwal University
                </option>
              </select> */}
              <select
                placeholder="Course Interested"
                class="form-field"
                name="course"
                required
              >
                <option value={""}>-- Select a Course --</option>
                <option value={"MCA"}>MCA</option>
                <option value={"BCA"}>BCA</option>
                <option value={"BSc."}>BSc.</option>
                <option value={"MBA"}>MBA</option>
                <option value={"BBA"}>BBA</option>
                <option value={"M.Com"}>M.Com</option>
                <option value={"B.Com"}>B.Com</option>
                <option value={"BA"}>BA</option>
                <option value={"MA"}>MA</option>
                <option value={"B.Lib"}>B.Lib</option>
                <option value={"M.Lib"}>M.Lib</option>
                <option value={"BSW"}>BSW</option>
                <option value={"MSW"}>MSW</option>
                <option value={"Others"}>Others</option>
              </select>
              <button type="submit" class="form-btn">
                Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default HomeForm;
