import React, { useState } from "react";
import "../Styles/Courses.css";
import Search from "../Images/search.jpg";
import MBA_bg from "../Images/Courses Bg/MBA_bg.png";
import MCA_bg from "../Images/Courses Bg/MCA_bg.png";
import BCA_bg from "../Images/Courses Bg/BCA_bg.png";
import BBA_bg from "../Images/Courses Bg/BBA_bg.png";
import MCom_bg from "../Images/Courses Bg/MCom_bg.png";
import BCom_bg from "../Images/Courses Bg/BCom_bg.png";
import MA_bg from "../Images/Courses Bg/MA_bg.png";
import BA_bg from "../Images/Courses Bg/BA_bg.png";
import MSW_bg from "../Images/Courses Bg/MSW_bg.png";
import BSW_bg from "../Images/Courses Bg/BSW_bg.png";
import MLib_bg from "../Images/Courses Bg/Mlib_bg.png";
import BLib_bg from "../Images/Courses Bg/Blib_bg.png";
import { data } from "./Data";
import PopUpForm from "./PopUpForm";
import { Typewriter } from 'react-simple-typewriter'

function Courses() {
  const [openPopUp, setOpenPopUp] = useState(false);

  const Course_bg = {
    MBA: MBA_bg,
    MCA:MCA_bg,
    BCA:BCA_bg,
    BBA: BBA_bg,
    MCom: MCom_bg,
    BCom: BCom_bg,
    MA: MA_bg,
    BA: BA_bg,
    MSW: MSW_bg,
    BSW: BSW_bg,
    MLib: MLib_bg,
    BLib: BLib_bg,
  };

  const words = [
    "MBA",
    "Master of Business Administration",
    "MCA",
    "Master of Computer Application",
    "BBA",
    "Bachelor of Business Administration",
    "BCA",
    "Bachelor of Computer Application",
    "B.Com",
    "Bachelor OF Commerce",
    "BA",
    "Bachelor of Arts",
    "MA",
    "Master of Arts",
    "M.Com",
    "Master of Commerce",
    "B.Li.Sc",
    "Bachelor of Library Science",
    "M.Li.Sc",
    "Master of Library Science",
    "BSW",
    "Bachelor of Social Work",
    "MSW",
    "Master of Social Work",
    "All"
  ];
  
  const reqValues={
      "MBA": "MBA",
    "Master of Business Administration": "MBA",
      "MCA": "MCA",
    "Master of Computer Application": "MCA",
    "BBA":"BBA",
    "Bachelor of Business Administration":"BBA",
    "BCA":"BCA",
    "Bachelor of Computer Application":"BCA",
    "B.Com":"BCom",
    "Bachelor OF Commerce":"BCom",
    "BA":"BA",
    "Bachelor of Arts":"BA",
    "MA":"MA",
    "Master of Arts":"MA",
    "M.Com":"MCom",
    "Master of Commerce":"MCom",
    "B.Li.Sc":"BLib",
    "Bachelor of Library Science":"BLib",
    "M.Li.Sc":"MLib",
    "Master of Library Science":"MLib",
    "BSW":"BSW",
    "Bachelor of Social Work":"BSW",
    "MSW":"MSW",
    "Master of Social Work":"MSW",
    "All":"All"
  };
  
  function findNearestMatch(input, words) {

    if (input==""){
      return "All";
    }
    // Remove all spaces and special characters from the input
    let cleanedInput = input.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  
    // Initialize the minimum distance to infinity and the best match to an empty string
    let minDistance = Infinity;
    let bestMatch = "";
  
    // Iterate over all the words and calculate the Levenshtein distance
    for (let i = 0; i < words.length; i++) {
      // Remove all spaces and special characters from the current word
      let cleanedWord = words[i].replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  
      // Calculate the Levenshtein distance between the cleaned input and the cleaned word
      let distance = levenshteinDistance(cleanedInput, cleanedWord);
  
      // If the current distance is less than the minimum distance, update the best match and the minimum distance
      if (distance < minDistance) {
        minDistance = distance;
        bestMatch = words[i];
      }
    }
  
    // Return the best match
    return bestMatch;
  }
  
  // Helper function to calculate the Levenshtein distance between two strings
  function levenshteinDistance(str1, str2) {
    let m = str1.length;
    let n = str2.length;
  
    // Initialize the distance matrix
    let distance = [];
    for (let i = 0; i <= m; i++) {
      distance[i] = [];
      distance[i][0] = i;
    }
    for (let j = 0; j <= n; j++) {
      distance[0][j] = j;
    }
  
    // Calculate the distance matrix
    for (let j = 1; j <= n; j++) {
      for (let i = 1; i <= m; i++) {
        if (str1[i - 1] === str2[j - 1]) {
          distance[i][j] = distance[i - 1][j - 1];
        } else {
          distance[i][j] = Math.min(
            distance[i - 1][j] + 1,
            distance[i][j - 1] + 1,
            distance[i - 1][j - 1] + 1
          );
        }
      }
    }
  
    // Return the Levenshtein distance
    return distance[m][n];
  }


  
  const [dropDownCourse, setDropDownCourse] = useState("All");
  const [searchInput,setSearchInput]=useState("All");
  const [selectedCourse,setSelectedCourse]=useState("All");

  const handleCourseChange = (event) => {
    setDropDownCourse(event.target.value);
    setSelectedCourse(event.target.value);

  };

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
    setSelectedCourse(reqValues[findNearestMatch(event.target.value,words)]);
  };

  const [readmoreActive, setreadmoreActive] = useState(false);
  const ReadmoreButton = () => {
    setreadmoreActive((prevState) => !prevState);
  };


  const course_data = data;

  const renderParagraphs = (arr) => {
    const paragraphs = [];
    for (let i = 1; i < arr.length; i++) {
      paragraphs.push(<li>{arr[i]}</li>);
    }
    return <ul>{paragraphs}</ul>;
  };


  const createSpecificCourseList = (arr, sel_course) => {
    if (sel_course == "All") {
      return arr;
    }
    const specific_course_data = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].category.valueOf() == sel_course) {
        specific_course_data.push(arr[i]);
      }
    }
    return specific_course_data;
  };

  return (
    <div>
      {/* <div class="courses-main-heading">
        <h1>Courses We Offer</h1>
      </div> */}

      <section class="serachanddrop">
        <h1>Courses We Offer</h1>
        <h3>
          Choose Your Course by{" "}
          <span
            style={{ fontSize: "1.4rem", fontWeight: "500", color: "white" }}
          >
            <Typewriter
              words={[
                "Selecting it from the Drop-down Menu... ",
                "Searching for it using the Search Box... ",
              ]}
              loop={false}
              cursor
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={2000}
            />
          </span>
        </h3>
        <div class="outer-searchbox">
          <select value={dropDownCourse} onChange={handleCourseChange}>
            <option value={"All"}>All Courses</option>
            <option value={"MBA"}>MBA</option>
            <option value={"MCA"}>MCA</option>
            <option value={"BBA"}>BBA</option>
            <option value={"BCA"}>BCA</option>
            <option value={"MCom"}>M.Com</option>
            <option value={"BCom"}>B.Com</option>
            <option value={"MA"}>MA</option>
            <option value={"BA"}>BA</option>
            <option value={"MSW"}>MSW</option>
            <option value={"BSW"}>BSW</option>
            <option value={"MLib"}>M.Lib</option>
            <option value={"BLib"}>B.Lib</option>
          </select>
          <div class="inner-search">
            <input
              type="text"
              class="search-filed"
              placeholder="Search Your Courses"
              value={searchInput}
              onChange={handleSearchInput}
            />
            <i class="fa fa-search"></i>
          </div>
        </div>
      </section>

      <section class="courses-card">
        {createSpecificCourseList(course_data, selectedCourse).map((item) => (
          <div class="courses-card-container-grid">
            <div class="courses-card-container-grid-left">
              <img src={Course_bg[item.category]} />
            </div>
            <div class="courses-card-container-grid-right">
              <h2>{item.title}</h2>
              <p>
                <b>Duration : </b>
                {item.duration}
              </p>
              <p>
                <b>Mode : </b>
                {item.mode}
              </p>
              <li>{item.description[0]}</li>

              {readmoreActive ? renderParagraphs(item.description) : ""}

              <h5 onClick={ReadmoreButton}>
                {readmoreActive ? "Read Less" : "Read More"} &rarr;
              </h5>
              <button
                onClick={() => {
                  setOpenPopUp(true);
                }}
              >
                Enroll Now
              </button>
            </div>
          </div>
        ))}
        {openPopUp && <PopUpForm closePopUp={setOpenPopUp} />}
      </section>
    </div>
  );
}

export default Courses;
