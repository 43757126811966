import React, { Component } from 'react'
import SignUp from '../Components/SignUp'

export class SignUpPage extends Component {
    render() {
      return (
        <div>
          <SignUp/>
        </div>
      )
    }
  }

export default SignUpPage
