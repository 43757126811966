import React, { Component } from 'react'
import ForgotPassword from '../Components/ForgotPassword'

export class ForgotPasswordPage extends Component {
    render() {
      return (
        <div>
          <ForgotPassword/>
        </div>
      )
    }
  }

export default ForgotPasswordPage
