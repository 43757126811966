import React,{useState} from 'react';
import "../Styles/SignUp.css";
import "../Styles/ForgotPassword.css";
import LoginSignUp from "../Images/LoginSignUp.jpg";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import Swal from "sweetalert2";

const ForgotPassword = () => {

  const [email, setEmail] = useState("");

  const forgotPass =(e) => {
    e.preventDefault();
    Swal.fire({
      title:"Loading...",
      icon: "https://www.boasnotas.com/img/loading2.gif",
      buttons: false,      
      closeOnClickOutside: false,
      timer: 3000,
    })
    sendPasswordResetEmail(auth,email).then(()=>{
      Swal.fire({
        title: "Reset-Email Sent Successfully",
        text:
          "Password reset email sent to " +email,
        icon: "success",
        confirmButtonText: "OK",
      }).then(function () {
        window.location.href = "/signin";
      });
    })

    .catch((error) => {
      console.log(error);
      Swal.fire({
          title: "Oops...",
          text: errorBeautify(error.toString()),
          icon: "error",
          confirmButtonText: "OK",
      });
    });
  }

  function errorBeautify(inp) {
    const output = inp
      .match(/\(auth\/(.*)\)/)[1]
      .split("-") // split the string at the hyphen
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
      .join(" "); // join the words with a space
    return output;
  }

  return (
    <div class="sign">
      <div class="SignUp-outer-container">
        <div class="SignUp-inner-left">
            <img src={LoginSignUp}/>
        </div>
        <div class="SignUp-inner-right">
            <div class="signup-form">
                <h1>Forgot Password</h1>
                <ul>
                    <li><i class="fa fa-hand-o-right"/>Enter Your Registered Email.</li>
                    <li><i class="fa fa-hand-o-right"/>Click on the Reset Password.</li>
                    <li><i class="fa fa-hand-o-right"/>Recieve Reset link on Email.</li>
                </ul>
                <form onSubmit={forgotPass}>
                    <input type={Text} placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)} ></input>
                    <button type='submit'>Reset Password</button>
                    <h4>Remember Password ? <a href='/signin'>Login</a></h4>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
