import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin,faFacebook,faInstagram,faTwitter} from "@fortawesome/free-brands-svg-icons";
import SquareImage from '../Images/shape.png';
import phone from '../Images/phone.png';
import email from '../Images/email.png';
import location from '../Images/location.png';
import { Link } from "react-router-dom";
import "../Styles/ContactUs.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, db } from "../firebase";
import Swal from "sweetalert2";
import { collection, doc, addDoc, setDoc } from "firebase/firestore";


function ContactUs(){

    function formatTimestamp(timestamp) {
        const dateObj = new Date(timestamp);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          };

        const formatter = new Intl.DateTimeFormat('en-GB', options);
        return formatter.format(dateObj);
    }

    const createDocument = async (formData) => {
        try {
          const docRef = doc(db, "Contact-Us-Form", formData.email);
          await setDoc(docRef, { Name: formData.name, Phone: formData.phone, Email: formData.email, Message: formData.message, Resolved: false, Date: formatTimestamp(Date.now())}).then(
          toast.success('Form Submitted', {
            position: toast.POSITION.TOP_RIGHT
        }));
        window.location.reload();
        } catch (error) {
          console.error("Error creating document: ", error);
          toast.error("Error creating document: " + error, {
            position: toast.POSITION.TOP_RIGHT
        });
        }
    };

    const onSubmits = (event) => {

        let formData = {
          name: event.target[0].value,
          email: event.target[1].value,
          phone: event.target[2].value,
          message: event.target[3].value,
        };
    
    
        var regName = /^(?! )[A-Za-z\s]+(?<! )$/;
        var phoneno = /^\d{10}$/;
        var emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

    
        if(!regName.test(formData.name)){
            event.preventDefault();
            toast.error("Name can contain only alphabets and spaces", {
                position: toast.POSITION.TOP_RIGHT
            });
        }else if(!emailRegex.test(formData.email)){
            event.preventDefault();
            toast.error('Please Check your Email Id.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }else if(!phoneno.test(formData.phone)){
            event.preventDefault();
            toast.error('Phone number can contain only 10 digits', {
                position: toast.POSITION.TOP_RIGHT
            });
        }else{
            event.preventDefault();
            createDocument(formData);
        }
        
      };


    return(
    <section>
        <div class="ContactUs-container">
        <span class="ContactUs-big-circle"></span>
        <img src={SquareImage} class="ContactUs-square" alt="" />
        <div class="ContactUs-form">
            <div class="ContactUs-contact-info">
            <h3 class="ContactUs-title">Let's get in touch !</h3>
            <p class="ContactUs-text">
                Fill out the form and our team will get back to you
                within 24 hours.

            </p>

            <div class="ContactUs-info">
                <div class="ContactUs-information">
                <img src={location} class="ContactUs-icon" alt="" />
                <p>Distance Study Hub, 5th floor ,Novel Office, Marathahalli Bridge, Bangalore-560037</p>
                </div>
                <div class="ContactUs-information">
                <img src={email} class="ContactUs-icon" alt="" />
                <p>distancestudyhub@gmail.com</p>
                </div>
                <div class="ContactUs-information">
                <img src={phone} class="ContactUs-icon" alt="" />
                <p>8726420202</p>
                </div>
            </div>

            <div class="ContactUs-social-media">
                <p>Connect with us :</p>
                <div class="ContactUs-social-icons">
                <a href="https://www.facebook.com/people/Li-Mat-Soft-Solutions-Pvt-Ltd/100063951045722/">
                    <FontAwesomeIcon icon={faFacebook}/>
                </a>
                <a href="https://twitter.com/solutions_limat">
                    <FontAwesomeIcon icon={faTwitter}/>
                </a>
                <a href="https://www.linkedin.com/company/limatsoft/?viewAsMember=true">
                    <FontAwesomeIcon icon={faInstagram}/>
                </a>
                <a href="https://www.instagram.com/limatsoftsolutions/">
                    <FontAwesomeIcon icon={faLinkedin}/>
                </a>
                </div>
            </div>
            </div>

            <div class="ContactUs-contact-form">
            <span class="ContactUs-circle one"></span>
            <span class="ContactUs-circle two"></span>

            <form onSubmit={onSubmits}>
                <h3 class="ContactUs-form-title">Contact us</h3>
                <div class="temporary"></div>
                <div class="ContactUs-input-container">
                    <input type="text" name="name" class="ContactUs-input" placeholder="Name" required/>
                </div>
                <div class="ContactUs-input-container">
                    <input type="email" name="email" class="ContactUs-input" placeholder="Email" required/>
                </div>
                <div class="ContactUs-input-container">
                    <input type="tel" name="phone" class="ContactUs-input" placeholder="Mobile Number" required/>
                </div>
                <div class="ContactUs-input-container-textarea">
                    <textarea name="message" class="ContactUs-input" placeholder="Write Your Message..." required></textarea>
                </div>
                <input type="submit" value="Send" class="ContactUs-btn" />
            </form>
            </div>
        </div>
        </div>
        <ToastContainer />
  </section>

    )
}

export default ContactUs;