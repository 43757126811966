import React, { Component } from 'react'
import Navbar from '../Components/NavBar'
import Footer from '../Components/Footer'
import Courses from '../Components/Courses'
import { Helmet } from 'react-helmet';
export class CoursesPage extends Component {
  render() {
    document.title = "ONLINE/DISTANCE LEARNING COURSES | PG and UG COURSES|PURSUE ONLINE MBA,BBA,MCA,BCA";
    return (
      <div>
        <Helmet>
          <meta name="keywords" content="online masters of business administration,online mba degree courses,master's degree in social work , distance learning courses, masters degree in healthcare management, mba in digital marketing, online mca,online bca,  online bba, bca online course,  bba in digital marketing, online mca course, bca distance education, bba distance education, online bba degree, online mca degree, bca course online, online mba courses in india,online mba,  online mba programs, online mba course" />
          <meta name="description" content="Get online MBA|MCA|BBA|BCA|PGDCA courses from the India's top universities offered for working professionals and freshers. Know more about Courses, Universities, Fees structure, Admission Process, Duration and more..." />
        </Helmet>
        <Navbar/>
        <Courses/>
        <Footer/>
      </div>
    )
  }
}

export default CoursesPage
