import React, { Component } from 'react'
import Navbar from '../Components/NavBar'
import Home from '../Components/Home'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';



export class HomePage extends Component {
  render() {

    document.title = "Best Online MBA|MCA|BBA|BCA|PGDCA program for Working Professionals";
    return (
      <div>
        <Helmet>
          <meta name="keywords" content="subharti university distance learning, swami vivekanand subharti university distance education, bba distance education" />
          <meta name="description" content="Get online MBA|MCA|BBA|BCA|PGDCA courses from the India's top universities offered for working professionals and freshers. Know more about Courses, Universities, Fees structure, Admission Process, Duration and more..." />
        </Helmet>
        <Navbar/>
        <Home/>
        <Footer/>
      </div>
    )
  }
}

export default HomePage
