import React, { Component } from 'react'
import Navbar from '../Components/NavBar'
import AboutUs from "../Components/AboutUs"
import Footer from '../Components/Footer'

export class AboutUsPage extends Component {
  render() {
    document.title = "BEST ONLINE AND DISTANCE EDUCATION CONSULTANT";
    return (
      <div>
        <Navbar/>
        <AboutUs/>
        <Footer/>
      </div>
    )
  }
}

export default AboutUsPage
