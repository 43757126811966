import { useRef , useEffect, useState } from "react";
import { FaBars, FaTimes} from "react-icons/fa";
import "../Styles/NavBar.css";
import logo from "../Images/logo.jpg"
import whatsapp from "../Images/whatsapp.png"
import { FaUserCircle } from "react-icons/fa";
import {auth} from '../firebase';
import Swal from "sweetalert2";

import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga";





function Navbar() {

	const location = useLocation();
	const currentPathname = location.pathname;

	const isLinkActive = (link) => {
	return currentPathname === link;
	};

	const navRef = useRef();

	const [isLogged,setIsLogged]=useState(false);

	useEffect(() => {

		// ReactGA.pageview(window.location.pathname + window.location.search); // non-interaction event

		auth.onAuthStateChanged((user) => {
		  if(user){
			setIsLogged(true);
		  }else{
			setIsLogged(false);
		  }
		})


	},[]);

	const showNavbar = () => {
		//interaction event
		// const path = window.location.pathname; // Get the path
		// const pathParts = path.split('/'); // Split the path by slashes
		// const pageName = pathParts[pathParts.length - 1]; // Get the last part (page name)

		// ReactGA.event({
		// 	'catagory': pageName,
		// 	'action': pageName + "viewed",
		// });
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	const Logout = () =>{

		auth.signOut().then(Swal.fire({
			title: "Logged Out Successfully",
			text: "See You Soon!!",
			icon: "success",
			confirmButtonText: "OK",
		  })).catch((error) => {
			console.log(error);
			Swal.fire({
			  title: "Oops...",
			  text: errorBeautify(error.toString()),
			  icon: "error",
			  confirmButtonText: "OK",
			});
		  })

	}

	const NavigateToSignIn = () =>{
		window.location.href = "/signin";
	}

	function errorBeautify(inp) {
		const output = inp
		  .match(/\(auth\/(.*)\)/)[1]
		  .split("-") // split the string at the hyphen
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
		  .join(" "); // join the words with a space
		return output;
	  }

	return (
		<header>
			<img src={logo} class="Nav-Logo" alt="LOGO" />
			<nav ref={navRef}>
				<a href="/" data-link="home" className={isLinkActive("/") ? "active" : ""}>Home</a>
				<a href="/about" data-link="about" className={isLinkActive("/about") ? "active" : ""}>AboutUs</a>
				<a href="/universities" data-link="universities" className={isLinkActive("/universities") ? "active" : ""}>Universities</a>
				<a href="/courses" data-link="courses" className={isLinkActive("/courses") ? "active" : ""}>Courses</a>
				<a href="/blogs" data-link="blogs" className={isLinkActive("/blogs") ? "active" : ""}>Blogs</a>
				{/* {isLogged?<a href="/upload">Registration</a>:<></>} */}
				<a href="/contact" data-link="contact" className={isLinkActive("/contact") ? "active" : ""}>ContactUs</a>
				{/* <button class="nav-a-signin" onClick={isLogged?Logout:NavigateToSignIn}><i><FaUserCircle/></i>{!isLogged?"Login/Register":"LogOut"}</button> */}
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
				<a class="nav-a-query" href="https://api.whatsapp.com/send?phone=918688882816">
					<img src={whatsapp} class="nav-wp-icon" alt="" />
					<p>Ask Us On 8726420202</p>
				</a>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;