import React, { useState } from "react";
import "../Styles/SignUp.css";
import "../Styles/SignIn.css";
import LoginSignUp from "../Images/LoginSignUp.jpg";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (e) => {
    e.preventDefault();
    {validateFields(email,password)?

      Swal.fire({
        title:"Loading...", 
        text: "Please Wait...",
        icon: "https://www.boasnotas.com/img/loading2.gif",
        timer: 3000,
      }).then(
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        userCredential.user.emailVerified?
        Swal.fire({
          title: "Login Successful",
          text: "Welcome Back !",
          icon: "success",
          confirmButtonText: "OK",
        }).then(function () {
          window.location.href = "/";
        }):
        Swal.fire({
          title: "Oops...",
          text: "Please Verify Your Email",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Oops...",
          text: errorBeautify(error.toString()),
          icon: "error",
          confirmButtonText: "OK",
        });
      })):e.preventDefault()}
  };

  function validateFields(email,password){
    var emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    var passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;

    if(!emailRegex.test(email)){
       toast.error('Please Check Your Email', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    else{
      return true;
    }

  }


  function errorBeautify(inp) {
    const output = inp
      .match(/\(auth\/(.*)\)/)[1]
      .split("-") // split the string at the hyphen
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
      .join(" "); // join the words with a space
    return output;
  }

  return (
    <div class="sign">
      <div class="SignUp-outer-container">
        <div class="SignUp-inner-left">
          <img src={LoginSignUp} alt=""/>
        </div>
        <div class="SignUp-inner-right">
          <div class="signup-form">
            <h1>Login</h1>
            <h2>Welcome back! please login</h2>
            <form onSubmit={signIn}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <input
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <div class="forgotpassword">
                <a href="/forgotpassword">Forgot Password ?</a>
              </div>
              <button type="submit">Sign In</button>
              <h4>
                Don't have an account ? <a href="/signup">SignUp</a>
              </h4>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
