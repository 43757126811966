import React, { Component } from 'react'
import Navbar from '../Components/NavBar'
import UniversitiesLanding from '../Components/UniversitiesLanding'
import Footer from '../Components/Footer'



export class UniversitiesPage extends Component {
  render() {
    document.title = "TOP ONLINE/DISTANCE EDUCATION UNIVERSITIES IN INDIA";
    return (
      <div>
        <Navbar/>
        <UniversitiesLanding/>
        <Footer/>
      </div>
    )
  }
}

export default UniversitiesPage
