import React, {useState} from 'react';
import "../Styles/AdminPage.css";
import Dashboard from '../Admin/Dashboard';
import EnquireDashboard from '../Admin/EnquireDashboard';
import ContactDashboard from '../Admin/ContactDashboard';

function AdminPage() {

    const [currDashboard,setCurrentDashboard]=useState(0);

  return (
    <div>
        <h1 className='OuterHeading'>Admin Dashboard</h1>

        <div className='AdminButtons'>
            <button className='UserDashboard' onClick={() => {setCurrentDashboard(0)}} style={currDashboard===0?{"color":"black"}:{}}>Users</button>
            <button className='EnquireDashboard' onClick={() => {setCurrentDashboard(1)}} style={currDashboard===1?{"color":"black"}:{}}>Enquire Form</button>
            <button className='ContactDashboard'onClick={() => {setCurrentDashboard(2)}} style={currDashboard===2?{"color":"black"}:{}}>Contact-Us</button>
        </div>

        <h2 className='InnerHeading'>{currDashboard===0?"User Dashboard":currDashboard===1?"Enquire Now Dashboard":"Contact-Us Dashboard"}</h2>

        <div>
            {currDashboard===0?<Dashboard/>:currDashboard===1?<EnquireDashboard/>:<ContactDashboard/>}
        </div>


      
    </div>
  )
}

export default AdminPage
