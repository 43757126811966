import React from "react";
import '../Styles/Footer.css';

export default function Footer() {
  return (
    <section class="main-footer">
      <footer>
        <div class="container">
          <div class="sec aboutus">
            <h2>About Us</h2>
            <p>
              Distance Study Hub is one of the leading educational Consultant
              in India for Online and distance learning courses. Established
              in 2020, our core focus is to provide education to all working
              enthusiasts who are not getting the growth due to less
              educational qualifications.
            </p>
            <ul class="sci">
            <li><a href="https://www.facebook.com/people/Li-Mat-Soft-Solutions-Pvt-Ltd/100063951045722/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a href="https://twitter.com/solutions_limat"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a href="https://www.instagram.com/limatsoftsolutions/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a href="https://www.linkedin.com/company/limatsoft/?viewAsMember=true"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <div class="sec quicklinks">
            <h2>Quick Links</h2>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/courses">Courses</a></li>
              <li><a href="/universities">Universities</a></li>
              <li><a href="blogs">Blogs</a></li>
              <li><a href="contact">Contact Us</a></li>
            </ul>
          </div>
          <div class="sec contact">
            <h2>Contact Us</h2>
            <ul class="info">
              <li>
                <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                   
                <span>Distance Study Hub,<br></br>
                5th floor ,Novel Office, <br></br>  Marathahalli Bridge,<br></br>Bangalore - 560037</span>
              </li>
              <li>
                <span><i class="fa fa-phone" aria-hidden="true"></i></span>
                <p><a href="tel:+91 8726420202">+91-8726420202</a></p>
              </li>
              <li>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
                <p><a href="mailto:distancestudyhub@gmail.com">distancestudyhub@gmail.com</a></p>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div class="copyrightText">
        <p>Copyright ⓒ 2023 Distance Study Hub. All Rights Reserved  |  Designed and Developed by Li-Mat Soft Solutios Pvt. Ltd.</p>
      </div>
    </section>
  );
}
