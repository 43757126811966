export const data = [
  {
    id: 1,
    category: "MBA",
    title: "MBA – Finance Management",
    duration: "2-Years",
    mode: "Online/Distance",
    description: [
      "An MBA in finance management is a graduate-level business degree that focuses on the principles and practices of financial management in a business context. This program typically covers topics such as financial analysis, investment strategies, risk management, financial markets, corporate finance, and financial accounting.",
      "The curriculum for an online MBA course in finance management typically includes a mix of core business courses, such as marketing, operations, and strategy, as well as specialized finance courses. In addition to classroom learning, students may also have the opportunity to participate in internships or consulting projects with real-world companies.",
      "Graduates with online MBA in finance management are often well-equipped for careers in a variety of industries, including investment banking, corporate finance, financial planning and analysis, portfolio management, and consulting. They may work in roles such as financial analyst, portfolio manager, investment banker, or financial advisor.",
      "Overall, online masters of business administration in finance management can provide a strong foundation for a successful career in the financial industry, and may open up opportunities for advancement and higher levels of responsibility.",
    ],
  },
  {
    id: 2,
    category: "MBA",
    title: "MBA – Human Resource Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "MBA in Human Resource Management is a graduate-level business degree program that focuses on the study of human resource management, a critical function within an organization. The program is designed to provide students with a comprehensive understanding of the principles, practices, and techniques involved in managing an organization's workforce. MBA in Human Resource Management is one of the best online MBA degree courses.",
      "Some of the key topics covered in an online MBA in Human Resource Management program include recruitment and selection, training and development, compensation and benefits, labor relations, organizational behavior, and strategic management.",
      "The program typically takes two years to complete and may be offered both on-campus and online. Admission requirements may vary, but typically include a bachelor's degree in a related field, relevant work experience, and standardized test scores.",
      "Graduates of an MBA in Human Resource Management program are prepared to take on leadership roles in human resources departments of organizations across various industries. They may work as human resource managers, training and development managers, labor relations specialists, or consultants, among other positions. The job outlook for individuals with an online MBA in Human Resource Management is promising, with a projected growth rate of 6% from 2019-2029, according to the U.S. Bureau of Labor Statistics. MBA in HR is one of the demanding course not only in regular courses but also  in online learning courses.",
    ],
  },
  {
    id: 3,
    category: "MBA",
    title: "MBA – Marketing Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Marketing Management is a graduate-level program that focuses on preparing students for managerial roles in the field of marketing. The program typically includes a mix of business and marketing courses that cover topics such as market research, consumer behavior, brand management, advertising and promotions, sales management, and digital marketing.",
      "An online masters of business administration in Marketing Management is one of the top distance learning courses. The program is designed to equip students with the skills and knowledge needed to understand customer needs, develop effective marketing strategies, and manage marketing campaigns across different channels. It also emphasizes the importance of data analysis and decision-making in marketing, as well as the ethical and social responsibilities of marketing professionals.",
      "An MBA in Marketing Management is ideal for individuals who wish to pursue a career in marketing or related fields such as advertising, public relations, market research, or brand management. Graduates of the program can find employment in various industries, including consumer goods, retail, technology, healthcare, and hospitality.",
      "To pursue an MBA in Marketing Management, candidates typically need to hold a bachelor's degree from an accredited institution, meet the minimum GPA requirements, and provide GRE or GMAT scores. Some programs may also require work experience in the field of marketing or a related field. The duration of the program can range from 1-2 years depending on the institution and the mode of study."
    ],
  },
  {
    id: 4,
    category: "MBA",
    title: "MBA – Information Technology Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Information Technology Management is a graduate-level program that combines traditional business coursework with technology-focused classes. The degree is designed to prepare students for leadership roles in technology-driven organizations, where they will need to understand the intersection of business and technology to make strategic decisions.",
      "The curriculum for an MBA in Information Technology Management typically includes courses such as : Business Strategy and Technology, Enterprise Architecture and IT Infrastructure, Managing IT Projects and Teams, IT Governance and Risk Management, Business Intelligence and Data Analytics, E-commerce and Digital Marketing, Innovation and Entrepreneurship",
      "Students will also gain experience through internships, case studies, and capstone projects that focus on real-world business and technology issues. The degree program can be completed in two years, though some schools offer accelerated or part-time options.",
      "Graduates of an MBA in Information Technology Management can pursue a variety of careers, including IT management, consulting, entrepreneurship, and business analysis. These professionals can work in a wide range of industries, including healthcare, finance, manufacturing, and retail.",
    ],
  },
  {
    id: 5,
    category: "MBA",
    title: "MBA – Operation and Production Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Operations and Production Management is a graduate degree program that focuses on equipping students with the skills and knowledge to manage the production and delivery of goods and services in a variety of industries. The program covers topics such as supply chain management, logistics, quality control, process improvement, project management, and operations strategy",
      "The program typically lasts for 1-2 years and is designed for professionals who are looking to advance their careers in operations and production management. Many MBA programs in this field also offer specialized tracks or concentrations, such as manufacturing, service operations, or global operations",
      "Graduates of an MBA in Operations and Production Management can pursue careers in a range of industries, including manufacturing, logistics, retail, healthcare, and consulting. Some common job titles for graduates include operations manager, supply chain manager, production manager, logistics manager, and project manager.",
      "To be admitted to an MBA in Operations and Production Management program, applicants typically need to have a bachelor's degree and several years of work experience. They may also need to submit GMAT or GRE scores and letters of recommendation, and participate in an admissions interview.",
    ],
  },
  {
    id: 6,
    category: "MBA",
    title: "MBA – Supply Chain Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Supply Chain Management is a graduate degree program designed to provide students with the skills and knowledge required to manage the flow of goods and services from suppliers to customers. The program is typically focused on the management of the entire supply chain, from procurement to delivery, and emphasizes the importance of efficient operations and effective communication with suppliers, distributors, and customers.",
      "The curriculum of an MBA in Supply Chain Management typically covers topics such as logistics management, inventory control, demand forecasting, procurement management, transportation management, and global supply chain management. Students may also have the opportunity to gain practical experience through internships or case studies.",
      "Upon completing an MBA in Supply Chain Management, graduates may be qualified for a range of positions in logistics, purchasing, and supply chain management, including roles such as supply chain analyst, procurement manager, logistics coordinator, operations manager, and supply chain director.",
      "In summary, an MBA in Supply Chain Management can provide graduates with the knowledge and skills needed to succeed in a variety of supply chain management roles, and can be a valuable credential for those seeking to advance their careers in this field.",
    ],
  },
  {
    id: 7,
    category: "MBA",
    title: "MBA – Project Leadership Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Project Leadership management is a graduate-level program that provides students with the knowledge and skills needed to effectively manage complex projects in various industries. This program typically covers topics such as project management methodologies, leadership and communication skills, risk management, financial management, and strategic planning.",
      "The goal of an MBA in Project Leadership management is to prepare students to lead and manage teams of professionals to successfully complete projects on time, within budget, and to the satisfaction of stakeholders. Graduates of this program can work in a variety of industries, including construction, healthcare, technology, finance, and consulting.",
      "Some of the courses that students may take in an MBA in Project Leadership management program include project planning and control, leadership and team management, project risk management, financial management for projects, strategic management, and global project management.",
      "After completing an MBA in Project Leadership management, graduates may find career opportunities as project managers, program managers, portfolio managers, or consultants in various industries. They may also choose to pursue certifications such as Project Management Professional (PMP) or Certified Associate in Project Management (CAPM) to further enhance their career prospects.",
    ],
  },
  {
    id: 8,
    category: "MBA",
    title: "MBA – Health Care Management",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "An MBA in Health Care Management is a graduate-level degree program that focuses on the business aspects of managing health care organizations. It is designed for individuals who want to pursue a career in the health care industry, but have an interest in management and administration rather than clinical practice.",
      "The curriculum for masters degree in Health Care Management typically includes courses in business management, finance, accounting, economics, marketing, operations management, and health care policy. Students may also take courses that focus specifically on the health care industry, such as health care ethics, health care law, and health care information systems.",
      "Upon completing masters degree in healthcare management, graduates may pursue a variety of careers in health care management, including hospital administration, health care consulting, health care marketing and sales, health care finance, and health care policy analysis. Salaries in the field vary depending on the position, level of experience, and location, but are generally competitive with other management positions in the health care industry.",
    ],
  },
  {
    id: 9,
    category: "MBA",
    title: "MBA – Fashion Designing",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
      "While there are MBA programs that specialize in Fashion Management or Fashion Merchandising, an MBA in Fashion Designing is not a common degree program.",
      "Typically, MBA programs are geared towards business administration, finance, marketing, and other related fields. On the other hand, fashion designing involves the creative and technical aspects of clothing and accessory design.",
      "If you are interested in pursuing a career in fashion designing, you might consider a degree in Fashion Design, Textile Design, or Fashion Merchandising. These programs typically cover topics such as design principles, garment construction, textile science, trend analysis, and marketing and sales strategies.",
      "However, if you are interested in the business side of the fashion industry, you might consider an MBA in Fashion Management or Fashion Merchandising. These programs can provide you with the knowledge and skills necessary to manage a fashion business, including marketing, finance, supply chain management, and strategic planning.",
      "Ultimately, the best degree program for you will depend on your interests and career goals. It's important to do your research and choose a program that aligns with your interests and strengths.",
    ],
  },
  {
    id: 10,
    category: "MBA",
    title: "MBA – Digital Marketing",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
     "An MBA in Digital Marketing is a specialized business degree program that focuses on the principles of business management and their application in the field of digital marketing. This type of MBA program is designed to equip students with the knowledge and skills required to thrive in the rapidly evolving world of online marketing, e-commerce, and digital advertising.",
     "Here are some key aspects of an MBA in Digital Marketing:",
     "Core Business Education: Like a traditional MBA program, an MBA in Digital Marketing will cover fundamental business topics such as finance, marketing, management, strategy, and operations. This provides students with a strong foundation in business management.",
     "Digital Marketing Specialization: The program will also include specialized courses in digital marketing. These courses may cover topics like search engine optimization (SEO), pay-per-click advertising (PPC), social media marketing, content marketing, email marketing, data analytics, and mobile marketing.",
     "An MBA in Digital Marketing can open doors to a wide range of career opportunities, such as digital marketing manager, social media manager, e-commerce manager, SEO specialist, and more. It's an ideal choice for individuals who want to advance their careers in digital marketing or pursue leadership roles in this field.",
     "When considering an online MBA program, be sure to research and select one that aligns with your career goals, offers a strong curriculum, and provides opportunities for practical experience and networking in the digital marketing industry.",

    ],
  },
  {
    id: 11,
    category: "MCA",
    title: "MCA – Master of Computer Application",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
     "An online MCA (Master of Computer Applications) program is a postgraduate degree in computer science and software development that is offered through online education platforms or distance learning institutions.  An online MCA program typically focuses on advanced computer science topics, software development, and information technology. This degree is suitable for individuals who want to advance their knowledge and skills in computer applications and software engineering but prefer the flexibility and accessibility of online learning.",
     "Here are some key features and  considerations  regarding  an  MCA  degree distance education:",
     "Core Curriculum: An MCA program covers a wide range of subjects, including programming languages, software engineering, database management, computer networks, web development, and data structures. It's designed to provide a strong foundation in computer science and application development.",
     "Flexibility: Online MCA programs are known for their flexibility. Students can access lectures, assignments, and course materials remotely, allowing them to balance their studies with work or other commitments.",
     "Online BBA courses typically take 3 years to complete and may require students to complete internships or other practical experiences to gain real-world business skills. Graduates of BBA programs may pursue careers in fields such as banking, consulting, marketing, sales, and human resources, among others.",
     "Specializations: Some online MCA programs offer specializations in areas like artificial intelligence, data science, cyber security, or mobile app development. These specializations allow you to focus on specific areas of interest.",
     "Exams and Assessments: Online MCA programs often use a combination of online exams, assignments, and projects to evaluate student performance.",
     "When considering an online MCA degree, it's essential to research the program's accreditation, faculty, curriculum, and delivery methods. Ensure that the program aligns with your career goals and interests. Many reputable universities and institutions offer online MCA programs, providing a high-quality education in the field of computer applications through the convenience of online learning.",
    ],
  },
  {
    id: 12,
    category: "BBA",
    title: "BBA – Bachelor of Business Administration",
    duration: "3-Years",
    mode: "Online/Distance Mode",
    description: [
     "Bachelor of Business Administration (BBA) is an undergraduate degree program that provides students with a comprehensive understanding of business principles and management practices. The degree is designed to prepare students for a career in the business world or to pursue further education in the field of business.",
     "The curriculum of an online BBA courses includes a broad range of business courses, such as accounting, finance, marketing, human resources, operations management, and organizational behavior. Students also have the opportunity to specialize in a particular area of business, such as entrepreneurship, international business, or information technology.",
     " Online BBA courses typically take 3 years to complete and may require students to complete internships or other practical experiences to gain real-world business skills. Graduates of BBA programs may pursue careers in fields such as banking, consulting, marketing, sales, and human resources, among others.",
     "Overall, an online BBA   degree can be a valuable asset for anyone looking to enter the world of business or to advance their career in the field.",
    ],
  },
  {
    id: 13,
    category: "BCA",
    title: "BCA – Bachelor of Computer Application",
    duration: "3-Years",
    mode: "Online/Distance Mode",
    description: [
     "Bachelor of Computer Applications (BCA) is a popular undergraduate program that focuses on computer science and its applications. Many universities and institutions offer online BCA distance education  programs through online distance learning, providing students with the flexibility to study from their own location.",
     "BCA online course covers core computer science topics, programming languages, database management, and other relevant subjects. Look for programs that align with your career goals and interests. Online distance learning programs typically offer course materials, lectures, and assignments through a virtual learning platform. Ensure that you have access to these resources and that they are user-friendly. Online learning offers flexibility, allowing you to study at your own pace and on your own schedule. Make sure the program allows you to balance your studies with other responsibilities.",
     "BCA  distance education program often include courses in programming languages such as C, C++, Java, and Python. Students learn how to write, debug, and optimize code. This subject teaches students how to design and implement data structures and algorithms, which are fundamental to software development. Students learn about database systems, SQL, and how to manage and retrieve data efficiently. They may also study NoSQL databases.",
     "Online BCA course often include web development topics, covering HTML, CSS, JavaScript, and web frameworks like PHP and ASP.NET. Students gain an understanding of operating system concepts, including process management, memory management, and file systems. Courses on networking cover the basics of computer networks, protocols, network security, and network administration.",
    ],
  },
  {
    id: 14,
    category: "BCom",
    title: "B.Com – Bachelor OF Commerce ",
    duration: "3-Years",
    mode: "Online/Distance Mode",
    description: [
     "B.Com, or Bachelor of Commerce, is an undergraduate degree program in commerce and related subjects. The program typically lasts for three years and is designed to provide students with a broad understanding of business, economics, accounting, and finance.",
     "The B.Com program usually includes courses in financial accounting, corporate accounting, cost accounting, business law, economics, statistics, management, marketing, and taxation. The curriculum may vary slightly depending on the university or institution offering the program.",
     "B.Com graduates have a wide range of career opportunities in the fields of finance, accounting, banking, insurance, auditing, taxation, and management. They can work in various industries, including manufacturing, retail, finance, consulting, and government.",
     "The B.Com program can also serve as a foundation for further studies in fields such as business management, finance, accounting, and economics.",
     "There are several benefits of pursuing a Bachelor of Commerce (B.Com) degree from a distance or online mode. Here are a few of them:",
     "Flexibility: Distance and online learning programs offer students the flexibility to study at their own pace and from anywhere. Students can access course materials and lectures at any time and can complete coursework around their schedule.",
     "Affordability: Distance and online learning programs are often more affordable than traditional on-campus programs. Students can save money on tuition fees, accommodation, and transportation costs.",
     "Accessibility: Online and distance learning programs provide access to education to students who may not have the ability to attend a traditional on-campus program due to geographical or other constraints.",
     "Customization: Online and distance learning programs often offer students the opportunity to customize their coursework to meet their specific interests and career goals.",
     "Career advancement: Pursuing a B.Com degree through distance or online learning can help students advance their careers without having to take time off work or relocate to attend a traditional on-campus program.",
     "Learning and technological skills: Distance and online learning require students to be self-motivated, disciplined, and proficient in technology. These skills are highly valued by employers and can help students succeed in their careers.",
    ],
  },
  {
    id: 15,
    category: "BA",
    title: "BA – Bachelor of Arts",
    duration: "3-Years",
    mode: "Online/Distance Mode",
    description: [
     "A Bachelor of Arts (BA) degree is an undergraduate academic degree that typically requires three to four years of study, depending on the country and the institution. The BA degree is generally focused on social sciences, humanities, fine arts, or liberal arts, and offers students a broad-based education in a variety of disciplines.",
     "BA programs often require students to take a range of courses outside their major, which can include general education requirements in areas such as math, science, and foreign languages. This interdisciplinary approach allows students to explore different fields and develop a well-rounded set of skills.",
     "The BA degree can prepare students for a wide range of careers, including journalism, education, social work, law, and public service. It can also be a foundation for graduate study in fields such as business, law, and the arts.",
    ],
  },
  {
    id: 16,
    category: "MA",
    title: "MA – Master of Arts",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
     "A Master of Arts degree, also known as an MA degree, is a graduate-level academic degree that typically takes one to two years to complete after earning a bachelor's degree. MA programs are offered in a wide range of disciplines, including but not limited to humanities, social sciences, business, education, and fine arts.",
     "MA programs typically require students to complete a certain number of courses in their chosen field of study, as well as a thesis or other culminating project. Students may also be required to pass exams or complete internships as part of their program.",
     "MA degrees are often pursued by individuals who want to deepen their knowledge in a particular subject or field, as well as those who want to pursue advanced study or research in their area of interest. They can also be a valuable credential for individuals seeking career advancement or higher-level positions in their field.",
     "There are several benefits to pursuing a Master of Arts (MA) degree from a distance or online, including:",
     "Flexibility: One of the primary advantages of pursuing a Master of Arts degree online is the flexibility it provides. Students can complete coursework at their own pace, and from any location with an internet connection. This allows students to balance their studies with work, family, and other commitments.",
     "Cost-Effective: Pursuing a Master of Arts degree online is often more cost-effective than attending a traditional on-campus program. Students can save money on commuting, housing, and other expenses associated with attending a physical campus.",
     "Access to Top Programs: Pursuing an online Master of Arts degree also provides access to some of the top programs in the country that may not be geographically accessible otherwise.",
     "Opportunity for Career Advancement: Earning a Master of Arts degree can provide a significant boost to a student's career prospects, leading to higher salaries and greater opportunities for advancement.",
     "Diverse Learning Opportunities: Online Master of Arts programs often attract students from all over the world, providing opportunities for diverse perspectives and cultural exchange.",
     "Customizable Learning Experience: Online Master of Arts programs often offer a wide range of courses and specializations, allowing students to customize their learning experience to suit their interests and career goals.",
     "Overall, pursuing a Master of Arts degree from a distance or online can provide a convenient, cost-effective, and customizable learning experience with significant career benefits.",
    ],
  },
  {
    id: 17,
    category: "MCom",
    title: "M.Com – Master of Commerce",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
     "Master of Commerce (M.Com) is a postgraduate degree program in commerce that focuses on various aspects of commerce such as accounting, economics, finance, marketing, taxation, and management. The program is designed to provide students with a deep understanding of various theoretical and practical concepts related to commerce and its related fields.",
     "The M.Com program is usually two years long and is offered by universities and colleges around the world. It is a popular choice for students who want to pursue a career in the corporate sector, financial services, banking, or entrepreneurship.",
     "The M.Com program is usually two years long and is offered by universities and colleges around the world. It is a popular choice for students who want to pursue a career in the corporate sector, financial services, banking, or entrepreneurship.",
     "To be eligible for an M.Com program, students typically need to have a bachelor's degree in commerce or a related field. However, some universities may accept students from other academic backgrounds if they have relevant work experience or additional qualifications.",
     "Upon completion of the M.Com program, graduates can pursue careers in various fields such as accounting, banking, financial analysis, marketing, and management. They can also choose to pursue further education by enrolling in doctoral programs in commerce or related fields.",
     "There are several benefits of pursuing M.Com (Master of Commerce) from a distance or online mode. Some of them include:",
     "Flexibility: One of the primary advantages of distance or online learning is the flexibility it offers. Students can access course material and complete assignments at their own pace and convenience, without having to follow a rigid schedule.",
     "Cost-effective: Distance or online learning is usually more cost-effective than traditional classroom-based learning. Students can save on transportation, accommodation, and other expenses associated with attending a physical campus.",
     "No geographical barriers: With online learning, there are no geographical barriers. Students can enroll in programs offered by universities located anywhere in the world, without having to relocate or commute to attend classes.",
     "Career advancement: Pursuing an M.Com degree through distance or online mode can help individuals advance their careers without having to take a break from work. It enables them to balance their work and study commitments more effectively.",
     "Improved digital skills: Online learning requires students to develop strong digital skills, which are essential in today's job market. Students become more comfortable using technology and working with digital resources, which can boost their employability.",
     "Access to a wide range of resources: Online learning platforms provide students with access to a wide range of resources, including recorded lectures, e-books, discussion forums, and online libraries. This enables them to learn at their own pace and access learning materials whenever they need them.",
     "Customized learning experience: Online learning platforms can be customized to suit the individual learning style and pace of each student. This helps students learn more effectively and efficiently, and allows them to focus on areas where they need more help.",
     "Overall, pursuing an M.Com degree through distance or online mode can offer several benefits, making it a popular choice among students.",
    ],
  },
  {
    id: 18,
    category: "BLib",
    title: "B.Li.Sc – Bachelor of Library Science",
    duration: "1-Year",
    mode: "Online/Distance Mode",
    description: [
     "A Bachelor of Library Science (B.Li.Sc) is an undergraduate academic degree program designed to prepare students for professional positions in library and information science. The program typically takes three years to complete and focuses on building the skills necessary to work in a library or information center.",
     "The curriculum of a B.Li.Sc program includes courses on library management, information organization and retrieval, reference services, cataloging, classification, digital libraries, and research methodology. Students also learn about the use of technology in libraries and information centers.",
     "After completing a B.Li.Sc program, graduates can work as librarians, information scientists, archivists, or in other related fields. They may also pursue advanced degrees in library and information science or related fields.",
     "Overall, a Bachelor of Library Science program provides students with the knowledge and skills needed to manage and organize information, assist library patrons, and contribute to the effective functioning of libraries and information centers.",
     "There are several benefits to pursuing a Bachelor of Library and Information Science (B.Lib) degree through distance/online mode, including:",
     "Flexibility: Online learning allows you to study from anywhere, at any time. This means you can fit your studies around your work, family, or other commitments.",
     "Cost-effective: Distance/online education is generally less expensive than traditional on-campus learning. You can save on transportation, accommodation, and other expenses associated with attending a physical campus.",
     "Self-paced learning: Distance/online courses allow you to learn at your own pace. You can take as much time as you need to complete a course, or you can accelerate your learning if you wish.",
     "Access to resources: Online courses often provide a wealth of resources, including online libraries, research databases, and discussion forums. This can make it easier for you to access the information you need to succeed in your studies.",
     "Career advancement: A B.Lib degree obtained through distance/online mode can help you advance your career in library and information science. It can also provide opportunities to work in a variety of fields such as archives, museums, publishing, and more.",
     "Networking: Online courses also provide opportunities to network with professionals in your field. You can connect with fellow students, professors, and alumni to build relationships that can help you advance in your career.",
     "Overall, pursuing a B.Lib degree through distance/online mode offers many benefits and can be an excellent option for individuals who value flexibility, convenience, and cost-effectiveness.",
    ],
  },
  {
    id: 19,
    category: "MLib",
    title: "M.Li.Sc – Master of Library Science",
    duration: "1-Year",
    mode: "Online/Distance Mode",
    description: [
     "M.Libis a Post graduate degree program that focuses on providing students with the skills and knowledge necessary to work in the field of library and information science. The MLS program covers a wide range of topics, including library management, information organization and retrieval, information technology, research methods, and information ethics.",
     "Students pursuing an MLS degree can expect to gain a strong understanding of the principles and practices of librarianship, as well as the skills necessary to manage libraries and provide access to information resources for patrons. MLS graduates are equipped to work in a variety of settings, including public libraries, academic libraries, special libraries, archives, and museums.",
     "To be eligible for an MLS program, students typically need to hold a bachelor's degree from an accredited college or university. Admission requirements vary by program, but typically include transcripts, letters of recommendation, a personal statement, and a standardized test score (such as the GRE). The length of an MLS program can vary, but most programs can be completed in one to two years of full-time study.",
     "Upon completion of an MLS program, graduates may pursue a variety of career paths in the library and information science field, including roles such as librarian, library director, archivist, information specialist, and more. Some MLS graduates also go on to pursue doctoral degrees in library and information science, which can lead to careers in research and academia.",
     "There are many universities and institutions that offer M.Lib (Master of Library and Information Science) programs through distance/online mode. These programs are designed to provide flexibility to students who are unable to attend regular classes due to personal or professional commitments.",
    ],
  },
  {
    id: 20,
    category: "BSW",
    title: "BSW – Bachelor of Social Work",
    duration: "3-Years",
    mode: "Online/Distance Mode",
    description: [
     "A Bachelor of Social Work (BSW) is an undergraduate degree program that typically takes four years to complete. The degree program prepares students for careers in the field of social work, which involves working with individuals, families, and communities to improve their well-being and quality of life.",
     "social welfare policy, human behavior and development, social justice, and ethics. They will also gain practical experience through internships and field placements in social service agencies, community organizations, and government agencies.",
     "Upon completing a BSW program, graduates may work in a variety of settings, such as child welfare agencies, mental health clinics, hospitals, schools, and community centers.",
    ],
  },
  {
    id: 21,
    category: "MSW",
    title: "MSW – Master of Social Work",
    duration: "2-Years",
    mode: "Online/Distance Mode",
    description: [
     "A  Master of Social Work (MSW) is a graduate-level degree program that provides advanced education and training for individuals who are interested in pursuing a career in social work. Master's degree in social work is a profession that is focused on helping individuals, families, groups, and communities to enhance their well-being and address various social issues.",
     "MSW programs typically take 2 years to complete and may require students to complete fieldwork or internships to gain practical experience. The curriculum typically covers topics such as social policy, human behavior, social work practice methods, research methods, and ethical considerations in social work.",
     "Graduates of MSW programs may pursue careers in various areas of social work, such as clinical social work, community social work, school social work, or policy advocacy. They may work in a variety of settings, including hospitals, mental health clinics, schools, government agencies, non-profit organizations, and private practice.",
     "Persuing  Master's degree in social work can provide graduates with the knowledge, skills, and credentials needed to advance their careers in social work and make a positive impact on the lives of the individuals, families, and communities they serve.",
    ],
  }

]; 
